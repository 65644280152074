var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", [
        _c(
          "div",
          {
            staticClass: "clearfix header",
            attrs: { slot: "header" },
            slot: "header",
          },
          [_c("span", [_vm._v("NRIC")])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row text",
            staticStyle: { margin: "auto", "text-align": "center" },
          },
          [
            _c("div", { staticClass: "column-ic" }, [
              _c("div", { staticClass: "center ic-box" }, [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAlertFront && _vm.icFrontImage === "",
                        expression: "showAlertFront && icFrontImage === ''",
                      },
                    ],
                    staticClass: "alertMessage",
                  },
                  [_vm._v(_vm._s(_vm.alertFrontMessage))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCameraOpen01,
                        expression: "isCameraOpen01",
                      },
                    ],
                    staticClass: "ic-image-box",
                  },
                  [
                    _c("video", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isPhotoTaken01,
                          expression: "!isPhotoTaken01",
                        },
                      ],
                      ref: "camera01",
                      attrs: {
                        id: "camera01",
                        width: "350",
                        height: "400",
                        autoplay: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("canvas", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPhotoTaken01,
                          expression: "isPhotoTaken01",
                        },
                      ],
                      ref: "canvas01",
                      attrs: { id: "photoTaken01" },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isPhotoTaken01,
                            expression: "isPhotoTaken01",
                          },
                        ],
                        staticClass: "watermark",
                      },
                      [_vm._v("For redONE only")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "center",
                  staticStyle: { "margin-top": "18px" },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCameraOpen01 && _vm.icFrontImage === "",
                          expression: "isCameraOpen01 && icFrontImage === ''",
                        },
                      ],
                      staticClass: "camera-shoot",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.captureFront()
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-camera" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isCameraOpen01 || _vm.icFrontImage !== ""
                    ? _c(
                        "el-button",
                        {
                          class: {
                            "is-primary": !_vm.isCameraOpen01,
                            "is-danger": _vm.isCameraOpen01,
                          },
                          attrs: { id: "snap", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleCamera01()
                            },
                          },
                        },
                        [_c("span", [_vm._v("SNAP IC FRONT")])]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "column-ic" }, [
              _c("div", { staticClass: "center ic-box" }, [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAlertBack && _vm.icBackImage === "",
                        expression: "showAlertBack && icBackImage === ''",
                      },
                    ],
                    staticClass: "alertMessage",
                  },
                  [_vm._v(_vm._s(_vm.alertBackMessage))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCameraOpen02,
                        expression: "isCameraOpen02",
                      },
                    ],
                    staticClass: "ic-image-box",
                  },
                  [
                    _c("video", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isPhotoTaken02,
                          expression: "!isPhotoTaken02",
                        },
                      ],
                      ref: "camera02",
                      attrs: {
                        id: "camera02",
                        width: "350",
                        height: "400",
                        autoplay: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("canvas", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPhotoTaken02,
                          expression: "isPhotoTaken02",
                        },
                      ],
                      ref: "canvas02",
                      attrs: { id: "photoTaken02" },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isPhotoTaken02,
                            expression: "isPhotoTaken02",
                          },
                        ],
                        staticClass: "watermark",
                      },
                      [_vm._v("For redONE only")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "center",
                  staticStyle: { "margin-top": "18px" },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCameraOpen02 && _vm.icBackImage === "",
                          expression: "isCameraOpen02 && icBackImage === ''",
                        },
                      ],
                      staticClass: "camera-shoot",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.captureBack()
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-camera" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isCameraOpen02 || _vm.icBackImage !== ""
                    ? _c(
                        "el-button",
                        {
                          class: {
                            "is-primary": !_vm.isCameraOpen02,
                            "is-danger": _vm.isCameraOpen02,
                          },
                          attrs: { id: "snap", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleCamera02()
                            },
                          },
                        },
                        [_c("span", [_vm._v("SNAP IC BACK")])]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("el-card", { staticStyle: { "margin-top": "18px" } }, [
        _c(
          "div",
          {
            staticClass: "clearfix header",
            attrs: { slot: "header" },
            slot: "header",
          },
          [_c("span", [_vm._v("Supporting Documents")])]
        ),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "row text" }, [
            _c(
              "div",
              {
                staticClass: "row",
                staticStyle: {
                  width: "100%",
                  padding: "18px",
                  "padding-bottom": "0px",
                },
              },
              [
                _c("div", { staticClass: "container" }, [
                  _c(
                    "div",
                    { attrs: { id: "slide" } },
                    _vm._l(_vm.images, function (item, index) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.images[0].src !== "",
                              expression: "images[0].src !== ''",
                            },
                          ],
                          key: index,
                          staticClass: "image-box",
                        },
                        [
                          _c("div", { staticClass: "image-back" }, [
                            _c("img", {
                              style: { height: item.height + "px" },
                              attrs: { src: item.src },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "doc-watermark" }, [
                            _c("span", [_vm._v("For redONE only")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "close",
                              on: {
                                click: function ($event) {
                                  return _vm.removeDoc(index)
                                },
                              },
                            },
                            [_vm._v("×")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.images.length > 0,
                      expression: "images.length > 0",
                    },
                  ],
                  staticClass: "control",
                },
                [
                  _c(
                    "p",
                    { staticClass: "prev", on: { click: _vm.previous } },
                    [_vm._v("\n              PREVIOUS\n            ")]
                  ),
                  _vm._v(" "),
                  _c("P", { staticClass: "next", on: { click: _vm.next } }, [
                    _vm._v("\n              NEXT\n            "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCameraOpenDoc,
                      expression: "isCameraOpenDoc",
                    },
                  ],
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("video", {
                    ref: "cameraDoc",
                    attrs: {
                      id: "cameraDoc",
                      width: "350",
                      height: "400",
                      autoplay: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("canvas", {
                    ref: "canvasDoc",
                    attrs: { id: "photoTakenDoc" },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "padding-top": "18px" } },
            [
              _c(
                "div",
                { staticClass: "center" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCameraOpenDoc,
                          expression: "isCameraOpenDoc",
                        },
                      ],
                      staticClass: "camera-shoot",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.captureDoc()
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-camera" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      class: {
                        "is-primary": !_vm.isCameraOpenDoc,
                        "is-danger": _vm.isCameraOpenDoc,
                      },
                      staticStyle: { width: "250px" },
                      attrs: {
                        id: "snap",
                        icon: "el-icon-camera",
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleCameraDoc()
                        },
                      },
                    },
                    [
                      !_vm.isCameraOpenDoc
                        ? _c("span", [_vm._v("SNAP SUPPORTING DOC")])
                        : _c("span", [_vm._v("CLOSE CAMERA")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "or-text" }, [_vm._v("OR")]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "is-primary",
                      staticStyle: { width: "250px" },
                      attrs: {
                        id: "snap",
                        icon: "el-icon-upload",
                        type: "button",
                      },
                      on: { click: _vm.attachSupportDoc },
                    },
                    [_c("span", [_vm._v("ATTACH SUPPORTING DOC")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showAlert,
                      expression: "showAlert",
                    },
                  ],
                  staticClass: "center alertMessage",
                },
                [_vm._v(_vm._s(_vm.alertMessage))]
              ),
              _vm._v(" "),
              _c("image-cropper", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.attachImageShow,
                    expression: "attachImageShow",
                  },
                ],
                key: _vm.attachImageKey,
                attrs: {
                  width: 350,
                  height: 400,
                  url: "https://httpbin.org/post",
                  "lang-type": "en",
                },
                on: {
                  close: _vm.close,
                  "crop-success": _vm.cropSuccess,
                  "crop-upload-success": _vm.cropUploadSuccess,
                  "crop-upload-fail": _vm.cropUploadFail,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("el-card", { staticStyle: { "margin-top": "18px" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "el-button",
                {
                  staticClass: "back-button",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [_vm._v("Back")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "el-button",
                {
                  staticClass: "next-button",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.nextPlan()
                    },
                  },
                },
                [_vm._v("Next")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
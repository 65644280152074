var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", [
        _c(
          "div",
          {
            staticClass: "clearfix header",
            attrs: { slot: "header" },
            slot: "header",
          },
          [_c("span", [_vm._v("Plan and Phone Number Info")])]
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.form,
                  rules: _vm.rules,
                  "label-width": "180px",
                },
              },
              [
                _c(
                  "el-form-item",
                  [
                    _c("el-checkbox", {
                      attrs: { label: "MNP", disabled: _vm.mnpCheck == 1 },
                      on: { change: _vm.chooseMNP },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.mnpShow
                  ? _c(
                      "el-form-item",
                      {
                        staticStyle: { "max-width": "550px" },
                        attrs: {
                          prop: "numberCategories",
                          label: "Number Categories",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.numberCategoryListLoading,
                                expression: "numberCategoryListLoading",
                              },
                            ],
                            ref: "select",
                            staticStyle: { width: "350px" },
                            attrs: {
                              placeholder:
                                "Please select one of the number categories",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.filterProjectTypes($event)
                              },
                            },
                            model: {
                              value: _vm.form.numberCategory,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "numberCategory", $$v)
                              },
                              expression: "form.numberCategory",
                            },
                          },
                          _vm._l(_vm.numberCategoryList, function (item) {
                            return _c("el-option", {
                              key: item.mvneServiceCode,
                              attrs: {
                                label: item.description,
                                value: item.mvneServiceCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.mnpShow
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "Mobile No" } },
                      [
                        _c("NumberPicker", {
                          attrs: {
                            "exclude-list": _vm.selectedMobileNos,
                            "number-category": _vm.form.numberCategory,
                            "number-picker-visible": _vm.numberPickerVisible,
                          },
                          model: {
                            value: _vm.form.mobileNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mobileNo", $$v)
                            },
                            expression: "form.mobileNo",
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.mobileNo
                          ? _c(
                              "span",
                              [
                                _vm._v("Selected Number: "),
                                _c("el-tag", [
                                  _vm._v(_vm._s(_vm.form.mobileNo)),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "max-width": "350px" },
                    attrs: { prop: "iccid", label: "ICCID: 896617" },
                  },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "13" },
                      on: { input: _vm.isNumber },
                      model: {
                        value: _vm.form.iccid,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "iccid", $$v)
                        },
                        expression: "form.iccid",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "max-width": "550px" },
                    attrs: { prop: "projectType", label: "Project Type" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.projectTypesLoading,
                            expression: "projectTypesLoading",
                          },
                        ],
                        ref: "select",
                        staticStyle: { width: "350px" },
                        attrs: {
                          placeholder: "Please select one of the project types",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSelectProjectType($event)
                          },
                        },
                        model: {
                          value: _vm.form.projectType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "projectType", $$v)
                          },
                          expression: "form.projectType",
                        },
                      },
                      _vm._l(_vm.projectTypes, function (item) {
                        return _c("el-option", {
                          key: item.code,
                          attrs: { label: item.name, value: item.code },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "max-width": "550px" },
                    attrs: { prop: "plan", label: "Plan" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.plansLoading,
                            expression: "plansLoading",
                          },
                        ],
                        ref: "select",
                        staticStyle: { width: "350px" },
                        attrs: {
                          placeholder: "Please select one of the plans",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSelectPlan($event)
                          },
                        },
                        model: {
                          value: _vm.form.plan,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "plan", $$v)
                          },
                          expression: "form.plan",
                        },
                      },
                      _vm._l(_vm.planDetails, function (item) {
                        return _c("el-option", {
                          key: item.code,
                          attrs: { label: item.name, value: item.code },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "max-width": "350px" },
                    attrs: { prop: "simCardFee", label: "Sim Card Fee" },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm._f("toCurrency")(_vm.form.simCardFee),
                        disabled: "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "max-width": "350px" },
                    attrs: {
                      prop: "registrationFee",
                      label: "Registration Fee",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm._f("toCurrency")(_vm.form.registrationFee),
                        disabled: "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "max-width": "350px" },
                    attrs: { prop: "creditLimit", label: "Credit Limit" },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm._f("toCurrency")(_vm.form.creditLimit),
                        disabled: "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "max-width": "350px" },
                    attrs: { prop: "contractPeriod", label: "Contract Period" },
                  },
                  [
                    _c("el-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.form.contractPeriod,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "contractPeriod", $$v)
                        },
                        expression: "form.contractPeriod",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "max-width": "350px" },
                    attrs: { prop: "depositAmount", label: "Deposit Amount" },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm._f("toCurrency")(_vm.form.depositAmount),
                        disabled: "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "max-width": "350px" },
                    attrs: {
                      prop: "advancedPayment",
                      label: "Advanced Payment",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm._f("toCurrency")(_vm.form.advancedPayment),
                        disabled: "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.mnpShow
                  ? _c("div", { staticClass: "dark-blue-bar" }, [
                      _vm._v("MNP Details"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.mnpShow
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              "max-width": "400px",
                              "padding-top": "18px",
                            },
                            attrs: {
                              prop: "mobileNoPortIn",
                              label: "Mobile No. to Port In",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { maxlength: "11" },
                              on: { input: _vm.isContactNumber },
                              model: {
                                value: _vm.form.mobileNoPortIn,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "mobileNoPortIn", $$v)
                                },
                                expression: "form.mobileNoPortIn",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "max-width": "550px" },
                            attrs: {
                              prop: "existingTelco",
                              label: "Existing Telco",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.existingTelcoLoading,
                                    expression: "existingTelcoLoading",
                                  },
                                ],
                                ref: "select",
                                staticStyle: { width: "350px" },
                                attrs: {
                                  placeholder:
                                    "Please select one of the existing telco",
                                },
                                model: {
                                  value: _vm.form.existingTelco,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "existingTelco", $$v)
                                  },
                                  expression: "form.existingTelco",
                                },
                              },
                              _vm._l(_vm.existingTelcos, function (item) {
                                return _c("el-option", {
                                  key: item.code,
                                  attrs: { label: item.name, value: item.code },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "max-width": "550px" },
                            attrs: {
                              prop: "identificationType",
                              label: "MNP ID Type",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.idTypesLoading,
                                    expression: "idTypesLoading",
                                  },
                                ],
                                ref: "select",
                                staticStyle: { width: "400px" },
                                attrs: {
                                  placeholder:
                                    "Please select one of the MNP ID type",
                                },
                                model: {
                                  value: _vm.form.identificationType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "identificationType",
                                      $$v
                                    )
                                  },
                                  expression: "form.identificationType",
                                },
                              },
                              _vm._l(_vm.idTypes, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "max-width": "400px" },
                            attrs: { prop: "idNo", label: "MNP ID No." },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.idNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "idNo", $$v)
                                },
                                expression: "form.idNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "max-width": "400px" },
                            attrs: { prop: "pinCode", label: "Pin Code" },
                          },
                          [
                            _c("el-input", {
                              attrs: { maxlength: "8" },
                              on: { input: _vm.isNumber },
                              model: {
                                value: _vm.form.pinCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "pinCode", $$v)
                                },
                                expression: "form.pinCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dark-blue-bar",
                    staticStyle: { "margin-top": "18px" },
                  },
                  [_vm._v("Manage VAS")]
                ),
                _vm._v(" "),
                _vm.form.plan !== ""
                  ? _c(
                      "el-form-item",
                      { staticStyle: { "margin-bottom": "0px" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            staticStyle: { "max-width": "550px" },
                            model: {
                              value: _vm.form.vasOptions,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "vasOptions", $$v)
                              },
                              expression: "form.vasOptions",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "IDD Activation (0.0)" },
                              on: { change: _vm.clickIDD },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.form.plan !== ""
                  ? _c("div", { staticClass: "black-line" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.predefinedOptions.length > 0 && _vm.form.plan !== ""
                  ? _c(
                      "el-form-item",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "margin-bottom": "0px",
                        },
                        attrs: { label: "PREDEFINED" },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            staticStyle: { "max-width": "550px" },
                            model: {
                              value: _vm.form.predefinedVas,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "predefinedVas", $$v)
                              },
                              expression: "form.predefinedVas",
                            },
                          },
                          _vm._l(_vm.predefinedOptions, function (item) {
                            return _c("el-checkbox", {
                              key: item.code,
                              attrs: {
                                checked: "",
                                label: item.name,
                                disabled: "",
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.availableOptions.length > 0 && _vm.form.plan !== ""
                  ? _c(
                      "el-form-item",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "margin-bottom": "0px",
                        },
                        attrs: { label: "AVAILABLE" },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            staticStyle: { "max-width": "550px" },
                            model: {
                              value: _vm.form.availableVas,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "availableVas", $$v)
                              },
                              expression: "form.availableVas",
                            },
                          },
                          _vm._l(_vm.availableOptions, function (item) {
                            return _c("el-checkbox", {
                              key: item.code,
                              attrs: { label: item.name, value: item.code },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      "max-width": "350px",
                      "padding-top": "18px",
                    },
                    attrs: { prop: "upFrontPayment", label: "Upfront Payment" },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm._f("toCurrency")(_vm.form.upFrontPayment),
                        placeholder: "฿0.00",
                        disabled: "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row ending-line" }, [
                  _c("div", { staticStyle: { width: "60%" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "column",
                        staticStyle: { "padding-top": "18px" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "black-button",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.clearAll()
                              },
                            },
                          },
                          [_vm._v("Clear")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "column",
                        staticStyle: { "padding-top": "18px" },
                      },
                      [
                        !_vm.isEdit
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading.body",
                                    value: _vm.saveLoading,
                                    expression: "saveLoading",
                                    modifiers: { body: true },
                                  },
                                ],
                                staticClass: "blue-button",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.save()
                                  },
                                },
                              },
                              [_vm._v("Add")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticClass: "blue-button",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(_vm.editIndex)
                                  },
                                },
                              },
                              [_vm._v("Edit")]
                            ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.lines.length > 0
        ? _c(
            "el-card",
            {
              staticStyle: { "margin-top": "18px" },
              attrs: { "body-style": "padding:0px;" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix header",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_c("span", [_vm._v("Line Details")])]
              ),
              _vm._v(" "),
              _vm._l(_vm.lines, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: {
                      "is-margin-bottom": index !== _vm.lines.length - 1,
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.editLines(index)
                          },
                        },
                      },
                      [
                        _c("back-to-top", {
                          attrs: {
                            "custom-style": _vm.myBackToTopStyle,
                            "visibility-height": 300,
                            "back-position": 50,
                            "transition-name": "fade",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticStyle: { padding: "18px" } }, [
                          _c("div", { staticClass: "black-bar" }, [
                            _vm._v(
                              "Line " + _vm._s(index + 1) + "\n            "
                            ),
                            index == 0 && _vm.custForm.idNo !== undefined
                              ? _c(
                                  "span",
                                  { staticStyle: { "padding-left": "18px" } },
                                  [
                                    _c("span", { staticClass: "master" }, [
                                      _vm._v("Master"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "box" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "column-2" }, [
                                _vm._v(
                                  "\n                Mobile No.\n                "
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(item.mobileNo))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "column-2" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading.body",
                                          value: _vm.removeLoading,
                                          expression: "removeLoading",
                                          modifiers: { body: true },
                                        },
                                      ],
                                      staticClass: "close",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeLine(index)
                                        },
                                      },
                                    },
                                    [_vm._v("×")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "column" }, [
                                _vm._v(
                                  "\n                ICCID\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "column" }, [
                                _vm._v(
                                  "\n                Plan\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(item.iccid))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(item.plan))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "column",
                                  staticStyle: { "margin-top": "18px" },
                                },
                                [
                                  _vm._v(
                                    "\n                Project Type\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "column",
                                  staticStyle: { "margin-top": "18px" },
                                },
                                [
                                  _vm._v(
                                    "\n                Upfront Payment\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(item.projectType))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          item.upFrontPayment
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mnpLines.length > 0
        ? _c(
            "el-card",
            {
              staticStyle: { "margin-top": "18px" },
              attrs: { "body-style": "padding:0px;" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix header",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_c("span", [_vm._v("Line Details")])]
              ),
              _vm._v(" "),
              _vm._l(_vm.mnpLines, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: {
                      "is-margin-bottom": index !== _vm.mnpLines.length - 1,
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.editLines(index)
                          },
                        },
                      },
                      [
                        _c("back-to-top", {
                          attrs: {
                            "custom-style": _vm.myBackToTopStyle,
                            "visibility-height": 300,
                            "back-position": 50,
                            "transition-name": "fade",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticStyle: { padding: "18px" } }, [
                          _c("div", { staticClass: "black-bar" }, [
                            _vm._v(
                              "Line " + _vm._s(index + 1) + "\n            "
                            ),
                            index == 0 && _vm.custForm.idNo !== undefined
                              ? _c(
                                  "span",
                                  { staticStyle: { "padding-left": "18px" } },
                                  [
                                    _c("span", { staticClass: "master" }, [
                                      _vm._v("Master"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "box" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "column-2" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "600" } },
                                  [_vm._v("MNP")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "column-2" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading.body",
                                          value: _vm.removeLoading,
                                          expression: "removeLoading",
                                          modifiers: { body: true },
                                        },
                                      ],
                                      staticClass: "close",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeLine(index)
                                        },
                                      },
                                    },
                                    [_vm._v("×")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "column" }, [
                                _vm._v(
                                  "\n                Mobile No. to Port In\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(item.mobileNoPortIn))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "column",
                                  staticStyle: { "margin-top": "18px" },
                                },
                                [
                                  _vm._v(
                                    "\n                ICCID\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "column",
                                  staticStyle: { "margin-top": "18px" },
                                },
                                [
                                  _vm._v(
                                    "\n                Plan\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(item.iccid))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(item.plan))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "column",
                                  staticStyle: { "margin-top": "18px" },
                                },
                                [
                                  _vm._v(
                                    "\n                Project Type\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "column",
                                  staticStyle: { "margin-top": "18px" },
                                },
                                [
                                  _vm._v(
                                    "\n                Upfront Payment\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(item.projectType))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "column" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          item.upFrontPayment
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("el-card", { staticStyle: { "margin-top": "18px" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "el-button",
                {
                  staticClass: "black-button",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [_vm._v("Back")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "el-button",
                {
                  staticClass: "blue-button",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.next()
                    },
                  },
                },
                [_vm._v("Next")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
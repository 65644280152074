"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _authService = require("@/utils/authService");

//
//
//
//
//
//
//
//
// import { isvalidUsername } from '@/utils/validate'
// import { login } from '@/utils/auth'
// import { RETURN_URL } from '@/constants/local_storage'
var _default = {
  data: function data() {
    return {
      redirect: undefined,
      emptyGif: 'https://wpimg.wallstcn.com/0e03b7da-db9e-4819-ba10-9016ddfdaed3'
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
        console.log(this.redirect);
      },
      immediate: true
    }
  },
  created: function created() {
    localStorage.setItem('RETURN_URL', this.$route.query.returnUrl);
    setTimeout(function () {
      (0, _authService.login)();
    }, 500);
  }
};
exports.default = _default;
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showAlertMessage
    ? _c(
        "el-alert",
        {
          staticClass: "alert-message",
          attrs: { type: _vm.alertMessage.type, closable: false },
        },
        [
          _c("h3", [_vm._v(" " + _vm._s(_vm.alertMessage.message) + " ")]),
          _vm._v(" "),
          _vm._l(_vm.alertMessage.details, function (item) {
            return _c("div", { key: item, staticClass: "alert-detail" }, [
              _vm._v("\n    - " + _vm._s(item) + "\n  "),
            ])
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  'jpg': 'image/jpg',
  'jpeg': 'image/jpeg'
};
exports.default = _default;
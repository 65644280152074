var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true },
        },
      ],
      staticClass: "createPost-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "editVasForm",
          attrs: {
            model: _vm.editVasForm,
            "label-width": "120px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c("alert-list", {
                attrs: { "alert-message": _vm.alertMessage },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "vasCode", label: "Code" },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.editVasForm.vasCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.editVasForm, "vasCode", $$v)
                      },
                      expression: "editVasForm.vasCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "name", label: "Name" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editVasForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editVasForm, "name", $$v)
                      },
                      expression: "editVasForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "vasId", label: "MVNE Vas ID" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editVasForm.vasId,
                      callback: function ($$v) {
                        _vm.$set(_vm.editVasForm, "vasId", $$v)
                      },
                      expression: "editVasForm.vasId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px", "margin-bottom": "4px" },
                  attrs: { prop: "price", label: "Price" },
                },
                [
                  _c("el-input-number", {
                    attrs: { precision: 2, step: 10, max: 1000, min: 0 },
                    model: {
                      value: _vm.editVasForm.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.editVasForm, "price", $$v)
                      },
                      expression: "editVasForm.price",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _c("span", { staticStyle: { "font-size": "8pt" } }, [
                      _vm._v(" Price must be same as MDS configuration"),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "description", label: "Description" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editVasForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.editVasForm, "description", $$v)
                      },
                      expression: "editVasForm.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "groupsCode", label: "Vas Group" },
                },
                [
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.vasGroupCodeLoading,
                          expression: "vasGroupCodeLoading",
                        },
                      ],
                      attrs: { placeholder: "Select" },
                      model: {
                        value: _vm.editVasForm.groupsCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.editVasForm, "groupsCode", $$v)
                        },
                        expression: "editVasForm.groupsCode",
                      },
                    },
                    _vm._l(_vm.vasGroupCode, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitEdit()
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
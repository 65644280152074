var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true },
        },
      ],
      staticClass: "createPost-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "addSupportingDocForm",
          attrs: {
            model: _vm.addSupportingDocForm,
            "label-width": "150px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c("alert-list", {
                attrs: { "alert-message": _vm.alertMessage },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "File Upload", prop: "attachment" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "file",
                      staticClass: "upload-add",
                      attrs: {
                        action: "",
                        "http-request": _vm.addAttachment,
                        "on-change": _vm.addAttachment,
                        "on-preview": _vm.handlePictureCardPreview,
                        "on-remove": _vm.handleRemove,
                        "before-remove": _vm.beforeRemove,
                        multiple: "",
                        "auto-upload": false,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.addSupportingDocForm.attachment,
                        "list-type": "picture-card",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v("files with a size less than 500kb each")]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        visible: _vm.dialogVisible,
                        title: _vm.dialogTitle,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogVisible = $event
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "100%",
                          src: _vm.dialogImageUrl,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitAdd()
                        },
                      },
                    },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
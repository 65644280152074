var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true },
        },
      ],
      staticClass: "createPost-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "addTncForm",
          attrs: {
            model: _vm.addTncForm,
            "label-width": "150px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c("alert-list", {
                attrs: { "alert-message": _vm.alertMessage },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { label: "File Upload", prop: "attachment" },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "file",
                      staticClass: "upload-tnc",
                      attrs: {
                        action: "",
                        "http-request": _vm.addAttachment,
                        "on-change": _vm.addAttachment,
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove,
                        "before-remove": _vm.beforeRemove,
                        multiple: "",
                        "auto-upload": false,
                        limit: 1,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.addTncForm.attachment,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("Click to upload")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v("files with a size less than 5mb")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "startDate", label: "Start Date" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Pick a Start Date",
                      format: "yyyy/MM/dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickStartDate,
                    },
                    model: {
                      value: _vm.addTncForm.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.addTncForm, "startDate", $$v)
                      },
                      expression: "addTncForm.startDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "endDate", label: "End Date" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Pick a End Date",
                      format: "yyyy/MM/dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickEndDate,
                    },
                    model: {
                      value: _vm.addTncForm.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.addTncForm, "endDate", $$v)
                      },
                      expression: "addTncForm.endDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitAdd()
                        },
                      },
                    },
                    [_vm._v("Create")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
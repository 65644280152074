var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _vm.checkPermission(["UserGroup_Manage"])
          ? _c(
              "router-link",
              { attrs: { to: "/user/groups/addGroup/" } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      id: "button",
                      type: "success",
                      icon: "el-icon-plus",
                    },
                  },
                  [_vm._v("Add Group")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            ref: "listTable",
            attrs: {
              data: _vm.tempList,
              "element-loading-text": "Loading",
              border: "",
              fit: "",
              "highlight-current-row": "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "#", width: "50" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.$index + 1))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Group Name", width: "250" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { align: "left", label: "Accesses" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !scope.row.expandMore,
                                expression: "!scope.row.expandMore",
                              },
                            ],
                          },
                          [
                            _vm._l(
                              scope.row.accesses.slice(0, 9),
                              function (item) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: item.name,
                                    staticStyle: {
                                      "margin-bottom": "2px",
                                      "margin-right": "2px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.description) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            scope.row.accesses.length > 4
                              ? _c("expand-hide-button", {
                                  attrs: {
                                    "expand-more": scope.row.expandMore,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.clickExpandMore(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.expandMore,
                                expression: "scope.row.expandMore",
                              },
                            ],
                          },
                          [
                            _vm._l(scope.row.accesses, function (item) {
                              return _c(
                                "el-tag",
                                {
                                  key: item.name,
                                  staticStyle: {
                                    "margin-bottom": "2px",
                                    "margin-right": "2px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.description) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            scope.row.accesses.length > 4
                              ? _c("expand-hide-button", {
                                  attrs: {
                                    "expand-more": scope.row.expandMore,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.clickMinimise(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.checkPermission(["UserGroup_Manage", "UserGroup_Delete"])
              ? _c("el-table-column", {
                  attrs: { label: "Operation", width: "180", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.checkPermission([
                              "UserGroup_Manage",
                              "UserGroup_Delete",
                            ])
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/user/groups/editGroup/" +
                                        scope.row.id,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          icon: "el-icon-edit",
                                          type: "text",
                                        },
                                      },
                                      [_vm._v("Edit")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.checkPermission(["UserGroup_Delete"])
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-delete",
                                      type: "text",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteGroupButton(scope)
                                      },
                                    },
                                  },
                                  [_vm._v("Delete")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2291780150
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
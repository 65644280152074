var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          background: "#409EFF",
          padding: "8px",
          "padding-left": "32px",
        },
      },
      [
        _c("span", { staticClass: "hello" }, [_vm._v("Hello!")]),
        _vm._v(" "),
        _c("span", { staticClass: "profile-name" }, [
          _vm._v(_vm._s(_vm.profile.username)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "group" }, [_vm._v("User Groups:")]),
        _vm._v(" "),
        _vm._l(_vm.profile.groups, function (group) {
          return _c(
            "span",
            { key: group.id, staticClass: "groupName blueback" },
            [_c("span", [_vm._v(_vm._s(group.groupName))])]
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dashboard-container" },
      [
        _c("panel-group", {
          on: { handleSetLineChartData: _vm.handleSetLineChartData },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dashboard = require("@/api/dashboard");

var state = {
  count: 0
};
var mutations = {
  SET_COUNT: function SET_COUNT(state, count) {
    state.count = count;
  }
};
var actions = {
  // get alert pending count
  getAlertPendingCount: function getAlertPendingCount(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      (0, _dashboard.getAlertPendingCount)().then(function (response) {
        var alert = response.data; // console.log('count' + JSON.stringify(alert))

        commit('SET_COUNT', alert[0].count);
        resolve(alert);
      }).catch(function (error) {
        console.log('get alert pending count error');
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;
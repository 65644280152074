var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true },
        },
      ],
      staticClass: "createPost-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "editGroupForm",
          attrs: {
            model: _vm.editGroupForm,
            "label-width": "120px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c("alert-list", {
                attrs: { "alert-message": _vm.alertMessage },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "groupName", label: "Group Name" },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.editGroupForm.groupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editGroupForm, "groupName", $$v)
                      },
                      expression: "editGroupForm.groupName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.groupAccessListLoading,
                      expression: "groupAccessListLoading",
                      modifiers: { body: true },
                    },
                  ],
                  attrs: {
                    prop: "accessList",
                    label: "Group Access:",
                    size: "small",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticStyle: { "max-width": "800px" },
                      model: {
                        value: _vm.editGroupForm.accessList,
                        callback: function ($$v) {
                          _vm.$set(_vm.editGroupForm, "accessList", $$v)
                        },
                        expression: "editGroupForm.accessList",
                      },
                    },
                    _vm._l(_vm.groupAccessList, function (access) {
                      return _c(
                        "el-checkbox",
                        { key: access.name, attrs: { label: access.name } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(access.description) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitEdit()
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
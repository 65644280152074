"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default: function _default() {
        return [10, 20, 30, 50];
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {// currentPage: this.page,
      // pageSize: this.limit
    };
  },
  computed: {
    currentPage: {
      get: function get() {
        return this.page;
      },
      set: function set(value) {
        this.$emit('update:page', value);
      }
    },
    pageSize: {
      get: function get() {
        return this.limit;
      },
      set: function set(value) {
        this.$emit('update:limit', value);
      }
    }
  },
  methods: {
    handleSizeChange: function handleSizeChange(value) {
      this.$emit('pagination', {
        page: this.currentPage,
        limit: value
      });
    },
    handleCurrentChange: function handleCurrentChange(value) {
      this.$emit('pagination', {
        page: value,
        limit: this.pageSize
      });
    }
  }
};
exports.default = _default2;
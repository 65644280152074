var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true },
        },
      ],
      staticClass: "createPost-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "150px" },
        },
        [
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c("alert-list", {
                attrs: { "alert-message": _vm.alertMessage },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "750px" },
                  attrs: { prop: "code", label: "Project Code:" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "750px" },
                  attrs: { prop: "name", label: "Project Name:" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "max-width": "550px" },
                      attrs: { prop: "startDate", label: "Start Date:" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "Pick a Start Date",
                          "picker-options": _vm.pickStartDate,
                        },
                        model: {
                          value: _vm.form.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "startDate", $$v)
                          },
                          expression: "form.startDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "max-width": "550px" },
                      attrs: { prop: "endDate", label: "End Date:" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "Pick an End Date",
                          "picker-options": _vm.pickEndDate,
                        },
                        model: {
                          value: _vm.form.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "endDate", $$v)
                          },
                          expression: "form.endDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "div",
                    {
                      staticClass: "header-mixin",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_c("span", [_vm._v("Group Configs:")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading.body",
                          value: _vm.groupListLoading,
                          expression: "groupListLoading",
                          modifiers: { body: true },
                        },
                      ],
                      attrs: {
                        size: "small",
                        prop: "groups",
                        label: "Groups:",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          staticStyle: { "max-width": "800px" },
                          model: {
                            value: _vm.form.groups,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "groups", $$v)
                            },
                            expression: "form.groups",
                          },
                        },
                        _vm._l(_vm.groupList, function (group) {
                          return _c(
                            "el-checkbox",
                            { key: group.name, attrs: { label: group.name } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(group.name) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "div",
                    {
                      staticClass: "header-mixin",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_c("span", [_vm._v("Package Configs:")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "max-width": "550px",
                        "padding-top": "18px",
                      },
                      attrs: { label: "Name:" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.packageListLoading,
                              expression: "packageListLoading",
                            },
                          ],
                          ref: "select",
                          staticStyle: { width: "300px" },
                          attrs: {
                            placeholder: "Please select one of the package",
                          },
                          model: {
                            value: _vm.package_code,
                            callback: function ($$v) {
                              _vm.package_code = $$v
                            },
                            expression: "package_code",
                          },
                        },
                        _vm._l(_vm.packageList, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading.body",
                          value: _vm.numberCategoryListLoading,
                          expression: "numberCategoryListLoading",
                          modifiers: { body: true },
                        },
                      ],
                      attrs: {
                        size: "small",
                        prop: "numberCategory",
                        label: "Number Category:",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          staticStyle: { "max-width": "800px" },
                          model: {
                            value: _vm.form.numberCategories,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "numberCategories", $$v)
                            },
                            expression: "form.numberCategories",
                          },
                        },
                        _vm._l(_vm.numberCategoryList, function (item) {
                          return _c(
                            "el-checkbox",
                            {
                              key: item.mvneServiceCode,
                              attrs: { label: item.mvneServiceCode },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.description) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "max-width": "550px",
                        "margin-bottom": "4px",
                      },
                      attrs: { label: "Signup Fee:" },
                    },
                    [
                      _c("el-input-number", {
                        attrs: { precision: 2, step: 1, max: 1000, min: 0 },
                        model: {
                          value: _vm.form.signupFee,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "signupFee", $$v)
                          },
                          expression: "form.signupFee",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _c("span", { staticStyle: { "font-size": "8pt" } }, [
                          _vm._v(" Tax Exclusive"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "max-width": "550px",
                        "margin-bottom": "4px",
                      },
                      attrs: { label: "Sim Card Fee:" },
                    },
                    [
                      _c("el-input-number", {
                        attrs: { precision: 2, step: 1, max: 1000, min: 0 },
                        model: {
                          value: _vm.form.simcardFee,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "simcardFee", $$v)
                          },
                          expression: "form.simcardFee",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _c("span", { staticStyle: { "font-size": "8pt" } }, [
                          _vm._v(" Tax Exclusive"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.packageList.length > 0
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.addPackageConfigs()
                            },
                          },
                        },
                        [_vm._v("Add")]
                      ),
                      _vm._v(" "),
                      _vm.packageConfigList.length <= 0
                        ? _c("el-form-item", {
                            attrs: { prop: "packageConfigList" },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "listTable",
                  staticStyle: { width: "85%", "margin-bottom": "22px" },
                  attrs: {
                    data: _vm.packageConfigList,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "#", width: "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.$index + 1))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Name" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Number Category", align: "left" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(
                            scope.row.numberCategories,
                            function (category) {
                              return _c(
                                "el-tag",
                                {
                                  key: category,
                                  staticStyle: {
                                    "margin-bottom": "2px",
                                    "margin-right": "2px",
                                  },
                                },
                                [_vm._v(_vm._s(category))]
                              )
                            }
                          )
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Signup Fee",
                      align: "right",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.signupFee))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Sim Card Fee",
                      align: "right",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.simcardFee))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Action", align: "center", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-button", {
                              attrs: { icon: "el-icon-delete", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.deletePackageConfigs(scope)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitCreate()
                        },
                      },
                    },
                    [_vm._v("Create")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true },
        },
      ],
      staticClass: "icons-container",
    },
    [
      _c("el-card", [
        _c(
          "div",
          {
            staticClass: "clearfix header",
            attrs: { slot: "header" },
            slot: "header",
          },
          [_c("span", [_vm._v("Account Details")])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "grid-container text",
            staticStyle: { padding: "0px 18px", "padding-left": "8px" },
          },
          [
            _vm.details.accountId > 0
              ? _c("div", { staticClass: "grid-item" }, [
                  _vm._v("Link with Account ID"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.details.accountId > 0
              ? _c("div", { staticClass: "grid-item" }, [_vm._v(":")])
              : _vm._e(),
            _vm._v(" "),
            _vm.details.accountId > 0
              ? _c("div", { staticClass: "grid-item" }, [
                  _vm._v(_vm._s(_vm.details.accountId)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [
              _vm._v("Submission Type"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [
              _vm._v(_vm._s(_vm.details.submissionType)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [_vm._v("Customer Type")]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
            _vm._v(" "),
            _vm.details.customerType !== null
              ? _c("div", { staticClass: "grid-item" }, [
                  _vm._v(_vm._s(_vm.details.customerType)),
                ])
              : _c("div", { staticClass: "grid-item" }, [_vm._v("-")]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [
              _vm._v("Identification No."),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
            _vm._v(" "),
            _vm.details.idNo !== null
              ? _c("div", { staticClass: "grid-item" }, [
                  _vm._v(_vm._s(_vm.details.idNo)),
                ])
              : _c("div", { staticClass: "grid-item" }, [_vm._v("-")]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [_vm._v("Name")]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
            _vm._v(" "),
            _vm.details.name !== null
              ? _c("div", { staticClass: "grid-item" }, [
                  _vm._v(_vm._s(_vm.details.name)),
                ])
              : _c("div", { staticClass: "grid-item" }, [_vm._v("-")]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [_vm._v("MNP Request")]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
            _vm._v(" "),
            _vm.details.mnp === true
              ? _c("div", { staticClass: "grid-item" }, [_vm._v("Yes")])
              : _c("div", { staticClass: "grid-item" }, [_vm._v("No")]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [
              _vm._v("Current Activation Status"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
            _vm._v(" "),
            _vm.details.status !== undefined
              ? _c(
                  "div",
                  {
                    class: {
                      "is-success status-padding-top":
                        _vm.details.status.code === 1,
                      "is-pending status-padding-top":
                        _vm.details.status.code === 0 ||
                        _vm.details.status.code === 5 ||
                        _vm.details.status.code === 6 ||
                        _vm.details.status.code === 7 ||
                        _vm.details.status.code === 8,
                      "is-failed status-padding-top":
                        _vm.details.status.code === 2 ||
                        _vm.details.status.code === 4 ||
                        _vm.details.status.code === 9 ||
                        _vm.details.status.code === 3,
                      "is-failed no-padding-top":
                        _vm.details.status.code === 3 &&
                        _vm.checkPermission(["Activation_Resubmit"]),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.details.status.description) +
                        "\n        "
                    ),
                    _vm.checkPermission(["Activation_Resubmit"]) &&
                    _vm.details.status.code === 3
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary" },
                            on: { click: _vm.resubmitRegistration },
                          },
                          [_vm._v("\n          Resubmit\n        ")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [_vm._v("Created Date")]),
            _vm._v(" "),
            _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
            _vm._v(" "),
            _vm.details.createdDate !== undefined
              ? _c("div", { staticClass: "grid-item" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("moment")(
                        _vm.details.createdDate,
                        "DD/MM/YYYY hh:mm:ss a"
                      )
                    )
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text", staticStyle: { margin: "18px 18px" } },
          [
            _vm.details.idType === "Business Registration No"
              ? _c("div", { staticClass: "black-bar" }, [
                  _vm._v("\n        Company Details\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.details.idType === "Business Registration No"
              ? _c(
                  "div",
                  {
                    staticClass: "box grid-container",
                    staticStyle: { "margin-left": "0px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "grid-item",
                        staticStyle: { "padding-top": "0px" },
                      },
                      [_vm._v("Contact Name")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "grid-item",
                        staticStyle: { "padding-top": "0px" },
                      },
                      [_vm._v(":")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "grid-item",
                        staticStyle: { "padding-top": "0px" },
                      },
                      [_vm._v(_vm._s(_vm.details.companyDetail.contactName))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v("Tax ID")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v(_vm._s(_vm.details.companyDetail.taxId)),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "status-container text",
            staticStyle: { padding: "18px", "padding-top": "0px" },
          },
          [
            _c("span", { staticClass: "status-up" }, [_vm._v("FMS Status")]),
            _vm._v(" "),
            _c("span", { staticClass: "status-up" }, [
              _vm._v("E-Wallet Deduction Status"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "status-up" }, [
              _vm._v("Account Creation Status"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "status-up" }, [
              _vm._v("Main Line Creation Status"),
            ]),
            _vm._v(" "),
            _vm.details.fmsStatus !== undefined
              ? _c(
                  "span",
                  {
                    staticClass: "status-down",
                    class: {
                      "is-success": _vm.details.fmsStatus.code === 1,
                      "is-pending": _vm.details.fmsStatus.code === 0,
                      "is-failed": _vm.details.fmsStatus.code === 2,
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.details.fmsStatus.description) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.details.ewalletDeductStatus !== undefined
              ? _c(
                  "span",
                  {
                    staticClass: "status-down",
                    class: {
                      "is-success": _vm.details.ewalletDeductStatus.code === 1,
                      "is-pending": _vm.details.ewalletDeductStatus.code === 0,
                      "is-failed": _vm.details.ewalletDeductStatus.code === 2,
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.details.ewalletDeductStatus.description) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.details.accountCreationStatus !== undefined
              ? _c(
                  "span",
                  {
                    staticClass: "status-down",
                    class: {
                      "is-success":
                        _vm.details.accountCreationStatus.code === 1,
                      "is-pending":
                        _vm.details.accountCreationStatus.code === 0,
                      "is-failed": _vm.details.accountCreationStatus.code === 2,
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.details.accountCreationStatus.description) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.details.mainLineCreationStatus !== undefined
              ? _c(
                  "span",
                  {
                    staticClass: "status-down",
                    class: {
                      "is-success":
                        _vm.details.mainLineCreationStatus.code === 1,
                      "is-pending":
                        _vm.details.mainLineCreationStatus.code === 0,
                      "is-failed":
                        _vm.details.mainLineCreationStatus.code === 2,
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.details.mainLineCreationStatus.description) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid-item", staticStyle: { "margin-left": "23px" } },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: "/Activation/Activations/AddSuppDoc/" + _vm.details.id,
                },
              },
              [
                _c(
                  "el-button",
                  { attrs: { icon: "el-icon-plus", type: "text" } },
                  [_vm._v("Add Supporting Document")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.details.submissionLogs !== null
        ? _c(
            "el-card",
            {
              staticStyle: { "margin-top": "18px" },
              attrs: { "body-style": "padding:0px;" },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.loadingLogs,
                      expression: "loadingLogs",
                      modifiers: { body: true },
                    },
                  ],
                  staticClass: "refresh",
                  attrs: {
                    icon: "el-icon-refresh",
                    size: "small",
                    type: "text",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getActivationDetails(_vm.id)
                    },
                  },
                },
                [_vm._v("Refresh\n    ")]
              ),
              _vm._v(" "),
              _c("expandable", {
                attrs: {
                  title: "Submission Logs",
                  "expand-more": _vm.expandSubmission,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.clickExpandSubmission()
                  },
                },
              }),
              _vm._v(" "),
              _vm.details.submissionLogs !== null &&
              _vm.expandSubmission === true
                ? _c(
                    "div",
                    [
                      _c(
                        "el-timeline",
                        {
                          staticStyle: {
                            "margin-top": "25px",
                            "margin-right": "25px",
                          },
                          attrs: { reverse: true },
                        },
                        _vm._l(_vm.timelineSubmission, function (item, index) {
                          return _c(
                            "el-timeline-item",
                            {
                              key: index,
                              attrs: {
                                icon: item.icon,
                                type: item.type,
                                color: item.color,
                                size: item.size,
                                timestamp: item.timestamp,
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.content) +
                                  "\n        "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticStyle: { "margin-top": "18px" },
          attrs: { "body-style": "padding:0px" },
        },
        [
          _c("expandable", {
            attrs: { title: "Line Details", "expand-more": _vm.expandLine },
            nativeOn: {
              click: function ($event) {
                return _vm.clickExpandLine()
              },
            },
          }),
          _vm._v(" "),
          _vm.expandLine === true && _vm.details.lineDetails !== null
            ? _c(
                "div",
                { staticClass: "text", staticStyle: { padding: "18px" } },
                _vm._l(_vm.details.lineDetails, function (line, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: {
                        "is-margin-bottom":
                          index !== _vm.details.lineDetails.length - 1,
                      },
                    },
                    [
                      _c("div", { staticClass: "black-bar" }, [
                        _vm._v("Line " + _vm._s(index + 1) + "\n          "),
                        line.isMainLine
                          ? _c(
                              "span",
                              { staticStyle: { "padding-left": "18px" } },
                              [
                                _c("span", { staticClass: "master" }, [
                                  _vm._v("Master"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "lineColumn" }, [
                          _c("div", { staticClass: "grid-container-line" }, [
                            _c("div", [_vm._v("Mobile No.")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(line.mobileNo))]),
                            _vm._v(" "),
                            _c("div", [_vm._v("ICCID")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(line.iccId))]),
                            _vm._v(" "),
                            _c("div", [_vm._v("Submission ID")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(line.submissionId))]),
                            _vm._v(" "),
                            _c("div", [_vm._v("Ewallet ID")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            line.ewalletId !== null
                              ? _c("div", [_vm._v(_vm._s(line.ewalletId))])
                              : _c("div", [_vm._v("-")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "vas-line",
                                staticStyle: { "padding-top": "18px" },
                              },
                              [_vm._v("Manage Vas")]
                            ),
                            _vm._v(" "),
                            _c("div"),
                            _vm._v(" "),
                            _c("div"),
                            _vm._v(" "),
                            _c("div", [_vm._v("IDD Activation")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            line.idd === true
                              ? _c("div", { staticClass: "isEnabled" }, [
                                  _vm._v("Enabled"),
                                ])
                              : _c("div", { staticClass: "isDisabled" }, [
                                  _vm._v("Disabled"),
                                ]),
                            _vm._v(" "),
                            _c("div", [_vm._v("Package")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(line.packageName))]),
                            _vm._v(" "),
                            _c("div", [_vm._v("Features")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              _vm._l(
                                line.vasFeatures,
                                function (vas, vasIndex) {
                                  return _c("span", { key: vasIndex }, [
                                    vasIndex !== line.vasFeatures.length - 1
                                      ? _c("span", [_vm._v(_vm._s(vas + ", "))])
                                      : _c("span", [_vm._v(_vm._s(vas))]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "grid-container-line" }, [
                            _c("div", { staticClass: "grid-item" }, [
                              _vm._v("Sim Card Fee"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "grid-item" }, [
                              _vm._v(":"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "grid-item" }, [
                              _vm._v(
                                "฿" +
                                  _vm._s(
                                    (line.simFee + line.simFeeTax).toFixed(2)
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v("Registration Fee")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "฿" +
                                  _vm._s(
                                    (line.regisFee + line.regisFeeTax).toFixed(
                                      2
                                    )
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v("Deposit Amount")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "฿" + _vm._s(line.defaultDeposit.toFixed(2))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v("Advanced Payment")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "฿" + _vm._s(line.advancedPayment.toFixed(2))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v("IDD / Roaming Amount")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("฿" + _vm._s(line.roamDeposit.toFixed(2))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "grid-item",
                                staticStyle: { "font-weight": "bold" },
                              },
                              [_vm._v("Status")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "grid-item",
                                staticStyle: { "font-weight": "bold" },
                              },
                              [_vm._v(":")]
                            ),
                            _vm._v(" "),
                            line.subscriptionStatus !== undefined
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    class: {
                                      "is-success":
                                        line.subscriptionStatus.code === 1,
                                      "is-pending":
                                        line.subscriptionStatus.code === 0,
                                      "is-failed":
                                        line.subscriptionStatus.code === 2,
                                    },
                                    staticStyle: { "font-weight": "bold" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          line.subscriptionStatus.description
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [_vm._v("Remarks")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(":")]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(line.remarks))]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "grid-item",
                                staticStyle: { "font-weight": "bold" },
                              },
                              [_vm._v("Total Payment")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "grid-item",
                                staticStyle: { "font-weight": "bold" },
                              },
                              [_vm._v(":")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "grid-item",
                                staticStyle: { "font-weight": "bold" },
                              },
                              [
                                _vm._v(
                                  "\n                ฿" +
                                    _vm._s(
                                      (
                                        line.simFee +
                                        line.simFeeTax +
                                        line.regisFee +
                                        line.regisFeeTax +
                                        line.defaultDeposit +
                                        line.roamDeposit +
                                        line.advancedPayment
                                      ).toFixed(2)
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "taxWording" }, [
                            _vm._v("*All fee included tax"),
                          ]),
                        ]),
                        _vm._v(" "),
                        line.mnpDetail !== null
                          ? _c(
                              "div",
                              { staticStyle: { "margin-top": "18px" } },
                              [
                                _c("div", { staticClass: "gray-bar" }, [
                                  _vm._v("MNP Details"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "lineColumn box" }, [
                                  _c(
                                    "div",
                                    { staticClass: "grid-container-mnp-line" },
                                    [
                                      _c("div", [_vm._v("Port in Mobile No.")]),
                                      _vm._v(" "),
                                      _c("div", [_vm._v(":")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(line.mnpDetail.portInCallerID)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [_vm._v("Port in Donor")]),
                                      _vm._v(" "),
                                      _c("div", [_vm._v(":")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(line.mnpDetail.portInDonor)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [_vm._v("Port in Name")]),
                                      _vm._v(" "),
                                      _c("div", [_vm._v(":")]),
                                      _vm._v(" "),
                                      line.mnpDetail.portInName !== ""
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(line.mnpDetail.portInName)
                                            ),
                                          ])
                                        : _c("div", [_vm._v("-")]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "grid-container-mnp-line" },
                                    [
                                      _c("div"),
                                      _vm._v(" "),
                                      _c("div"),
                                      _vm._v(" "),
                                      _c("div"),
                                      _vm._v(" "),
                                      _c("div", [_vm._v("Port in Id No.")]),
                                      _vm._v(" "),
                                      _c("div", [_vm._v(":")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(line.mnpDetail.portInIdNo)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [_vm._v("Pin Code")]),
                                      _vm._v(" "),
                                      _c("div", [_vm._v(":")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(_vm._s(line.mnpDetail.pinCode)),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticStyle: { "margin-top": "18px" },
          attrs: { "body-style": "padding:0px" },
        },
        [
          _c("expandable", {
            attrs: {
              title: "User Remarks",
              "expand-more": _vm.expandUserRemarks,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.clickExpandUserRemarks()
              },
            },
          }),
          _vm._v(" "),
          _vm.expandUserRemarks === true
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "grid-container-user-remark" },
                    [
                      _c(
                        "div",
                        { staticStyle: { "padding-top": "8px" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Enter Remark" },
                            model: {
                              value: _vm.userRemarks,
                              callback: function ($$v) {
                                _vm.userRemarks = $$v
                              },
                              expression: "userRemarks",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "add-button",
                          attrs: { type: "primary" },
                          on: { click: _vm.addUserRemarks },
                        },
                        [_vm._v("\n          Add\n        ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c(
                    "el-timeline",
                    {
                      staticStyle: {
                        "margin-top": "25px",
                        "margin-right": "18px",
                      },
                      attrs: { reverse: true },
                    },
                    _vm._l(_vm.timelineUserRemarks, function (item, index) {
                      return _c(
                        "el-timeline-item",
                        {
                          key: index,
                          staticStyle: { "margin-top": "0px" },
                          attrs: {
                            icon: item.icon,
                            type: item.type,
                            color: item.color,
                            size: item.size,
                            timestamp: item.timestamp,
                          },
                        },
                        [
                          _c("p", { class: item.boldContentClass }, [
                            _vm._v(_vm._s(item.remarks) + "\n            "),
                            _c(
                              "span",
                              { staticClass: "content-font-less-bold" },
                              [_vm._v(" " + _vm._s(item.addedBy))]
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "18px", "margin-bottom": "50px" } },
        [
          _c(
            "div",
            {
              staticClass: "clearfix header",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_vm._v("Agent Details")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "grid-container text",
              staticStyle: {
                padding: "18px",
                "padding-left": "8px",
                "padding-top": "0px",
              },
            },
            [
              _c("div", { staticClass: "grid-item" }, [_vm._v("Agent ID")]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-item" }, [
                _vm._v(_vm._s(_vm.details.agentId)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-item" }, [_vm._v("Agent Name")]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-item" }, [
                _vm._v(_vm._s(_vm.details.username)),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
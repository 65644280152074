"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  // token: state => state.user.token,
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  profile: function profile(state) {
    return state.user.profile;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  count: function count(state) {
    return state.alertNotification.count;
  }
};
var _default = getters;
exports.default = _default;
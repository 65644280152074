var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true },
        },
      ],
      staticClass: "createPost-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "addVasGroupForm",
          attrs: {
            model: _vm.addVasGroupForm,
            "label-width": "120px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c("alert-list", {
                attrs: { "alert-message": _vm.alertMessage },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "groupsCode", label: "Code" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addVasGroupForm.groupsCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.addVasGroupForm, "groupsCode", $$v)
                      },
                      expression: "addVasGroupForm.groupsCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "groupsName", label: "Name" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addVasGroupForm.groupsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addVasGroupForm, "groupsName", $$v)
                      },
                      expression: "addVasGroupForm.groupsName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "groupsType", label: "Type" },
                },
                [
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.vasGroupTypeLoading,
                          expression: "vasGroupTypeLoading",
                        },
                      ],
                      attrs: { placeholder: "Select" },
                      model: {
                        value: _vm.addVasGroupForm.groupsType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addVasGroupForm, "groupsType", $$v)
                        },
                        expression: "addVasGroupForm.groupsType",
                      },
                    },
                    _vm._l(_vm.vasGroupType, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.description, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitAdd()
                        },
                      },
                    },
                    [_vm._v("Create")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ElBadge',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: [String, Number],
    // eslint-disable-next-line vue/require-default-prop
    max: Number,
    isDot: Boolean,
    hidden: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    type: {
      type: String,
      validator: function validator(val) {
        return ['primary', 'success', 'warning', 'info', 'danger'].indexOf(val) > -1;
      }
    }
  },
  computed: {
    content: function content() {
      if (this.isDot) return;
      var value = this.value;
      var max = this.max;

      if (typeof value === 'number' && typeof max === 'number') {
        return max < value ? "".concat(max, "+") : value;
      }

      return value;
    }
  }
};
exports.default = _default;
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c(
          "el-select",
          {
            staticClass: "filter-item",
            attrs: {
              placeholder: "Search by Group",
              loading: _vm.groupLoading,
              filterable: "",
              clearable: "",
              "loading-text": "",
            },
            on: { change: _vm.searchClick },
            model: {
              value: _vm.filterByGroup,
              callback: function ($$v) {
                _vm.filterByGroup = $$v
              },
              expression: "filterByGroup",
            },
          },
          _vm._l(_vm.options, function (item) {
            return _c("el-option", {
              key: item.id,
              attrs: { value: String(item.id), label: item.name },
            })
          }),
          1
        ),
        _vm._v(" "),
        _c("el-input", {
          staticClass: "filter-item",
          staticStyle: { width: "400px" },
          attrs: { placeholder: "Filter", clearable: "" },
          on: { input: _vm.onChangeInput },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.searchClick($event)
            },
          },
          model: {
            value: _vm.filterText,
            callback: function ($$v) {
              _vm.filterText = $$v
            },
            expression: "filterText",
          },
        }),
        _vm._v(" "),
        _c(
          "badge",
          { staticClass: "item", attrs: { "is-dot": _vm.isDot } },
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { "margin-left": "5px" },
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.searchClick },
              },
              [_vm._v("\n        Search\n      ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.checkPermission(["User_Manage"])
          ? _c(
              "router-link",
              { attrs: { to: "/user/users/new" } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    attrs: {
                      id: "button",
                      type: "success",
                      icon: "el-icon-plus",
                    },
                  },
                  [_vm._v("Add User")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.listLoading === false,
                expression: "listLoading === false",
              },
            ],
          },
          [
            _c(
              "el-button",
              {
                attrs: { id: "button", type: "text", size: "medium" },
                on: {
                  click: function ($event) {
                    return _vm.exportClick()
                  },
                },
              },
              [_vm._v("Export Result ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading.body",
                value: _vm.listLoading,
                expression: "listLoading",
                modifiers: { body: true },
              },
            ],
            attrs: {
              data: _vm.tempList,
              "element-loading-text": "Loading",
              border: "",
              fit: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                fixed: "left",
                align: "center",
                label: "#",
                width: "50",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getRecordNumber(scope.$index)) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                fixed: "left",
                prop: "name",
                "min-width": "150",
                label: "Name",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                fixed: "left",
                prop: "username",
                "min-width": "150",
                label: "Username",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "email", "min-width": "200", label: "Email" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { align: "left", label: "Groups", width: "180" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return _vm._l(scope.row.groups, function (item) {
                      return _c(
                        "el-tag",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.name !== null,
                              expression: "item.name !== null",
                            },
                          ],
                          key: item.id,
                          staticStyle: {
                            "margin-bottom": "2px",
                            "margin-right": "2px",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.name) +
                              "\n          "
                          ),
                        ]
                      )
                    })
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { width: "300", align: "left", label: "Accesses" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      !scope.row.expandMore
                        ? _c(
                            "div",
                            [
                              _vm._l(
                                scope.row.accesses.slice(0, 4),
                                function (item) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: item.name,
                                      staticStyle: {
                                        "margin-bottom": "2px",
                                        "margin-right": "2px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.description) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              scope.row.accesses.length > 4
                                ? _c("expand-hide-button", {
                                    attrs: {
                                      "expand-more": scope.row.expandMore,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.clickExpandMore(scope.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c(
                            "div",
                            [
                              _vm._l(scope.row.accesses, function (item) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: item.name,
                                    staticStyle: {
                                      "margin-bottom": "2px",
                                      "margin-right": "2px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.description) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              scope.row.accesses.length > 4
                                ? _c("expand-hide-button", {
                                    attrs: {
                                      "expand-more": scope.row.expandMore,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.clickMinimise(scope.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { align: "center", label: "Status", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            type: _vm.getStatusTagType(scope.row.status.code),
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.status.description) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.checkPermission(["User_Manage"])
              ? _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    label: "Operations",
                    width: "120",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/user/users/edit/" + scope.row.id,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "text", size: "small" } },
                                  [_vm._v("Edit")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4132500286
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.totalRecords > 0,
              expression: "totalRecords>0",
            },
          ],
          attrs: { total: _vm.totalRecords, page: _vm.page, limit: _vm.limit },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "update:limit": function ($event) {
              _vm.limit = $event
            },
            pagination: _vm.pageInfoChanged,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
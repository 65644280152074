var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("alert-list", { attrs: { "alert-message": _vm.alertMessage } }),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.custForm.contactNo1 !== undefined &&
          _vm.subCustForm.mobileNo === undefined
            ? _c("el-card", { attrs: { "body-style": "padding:0px;" } }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix header",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Customer Information")])]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "block" }, [
                  _vm.custForm.companyName !== ""
                    ? _c("div", { staticClass: "grid-container" }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-item",
                            staticStyle: { "padding-top": "8px" },
                          },
                          [_vm._v("Company Name")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "grid-item",
                            staticStyle: { "padding-top": "8px" },
                          },
                          [_vm._v(":")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "grid-item",
                            staticStyle: { "padding-top": "8px" },
                          },
                          [_vm._v(_vm._s(_vm.custForm.companyName))]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid-item" }, [
                          _vm._v("Business Registration No."),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid-item" }, [
                          _vm._v(_vm._s(_vm.custForm.taxID)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid-item" }, [
                          _vm._v("Authorised Name"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid-item" }, [
                          _vm._v(_vm._s(_vm.custForm.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid-item" }, [
                          _vm._v("Authorised Contact No."),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid-item" }, [
                          _vm._v(_vm._s(_vm.custForm.contactNo1)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "grid-container" }, [
                    _vm.custForm.companyName === ""
                      ? _c(
                          "div",
                          {
                            staticClass: "grid-item",
                            staticStyle: { "padding-top": "8px" },
                          },
                          [_vm._v("Name")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.custForm.companyName === ""
                      ? _c(
                          "div",
                          {
                            staticClass: "grid-item",
                            staticStyle: { "padding-top": "8px" },
                          },
                          [_vm._v(":")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.custForm.companyName === ""
                      ? _c(
                          "div",
                          {
                            staticClass: "grid-item",
                            staticStyle: { "padding-top": "8px" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.custForm.salutation +
                                  " " +
                                  _vm.custForm.name
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v("Identification No."),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v(_vm._s(_vm.custForm.idNo)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v("DOB")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v(_vm._s(_vm.custForm.date)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v("Gender")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v(_vm._s(_vm.custForm.gender)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v("Nationality"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v(_vm._s(_vm.custForm.nationality)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v("Preferred Language"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v(_vm._s(_vm.custForm.language)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v("NRIC Address"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v(
                        _vm._s(_vm.custForm.addressLineNo1 + ", ") +
                          "\n            "
                      ),
                      _vm.custForm.village1 !== ""
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.custForm.village1 + ", ")),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.custForm.moo1 !== ""
                        ? _c("span", [_vm._v(_vm._s(_vm.custForm.moo1 + ", "))])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.custForm.soi1 !== ""
                        ? _c("span", [_vm._v(_vm._s(_vm.custForm.soi1 + ", "))])
                        : _vm._e(),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.custForm.road1 +
                              ", " +
                              _vm.custForm.subDistrict1 +
                              ", " +
                              _vm.custForm.district1 +
                              ", " +
                              _vm.custForm.province1 +
                              ", " +
                              _vm.custForm.zipCode1
                          )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v("Contact No."),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v(_vm._s(_vm.custForm.contactNo1)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v("Email")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v(_vm._s(_vm.custForm.email1)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v("Data Consent"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _vm.custForm.dataConsent !== undefined
                      ? _c(
                          "div",
                          { staticClass: "grid-item" },
                          [
                            _vm._l(
                              _vm.custForm.dataConsent,
                              function (form, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: {
                                      float: "left",
                                      "padding-right": "5px",
                                    },
                                  },
                                  [
                                    index !==
                                    _vm.custForm.dataConsent.length - 1
                                      ? _c("span", [_vm._v(_vm._s(form + ","))])
                                      : _c("span", [_vm._v(_vm._s(form))]),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _vm.custForm.dataConsent.length === 0
                              ? _c("div", [_vm._v("None")])
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "grid-item",
                        staticStyle: { "padding-bottom": "8px" },
                      },
                      [_vm._v("Billing Address")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "grid-item",
                        staticStyle: { "padding-bottom": "8px" },
                      },
                      [_vm._v(":")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "grid-item",
                        staticStyle: { "padding-bottom": "8px" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.custForm.addressLineNo2 + ", ") +
                            "\n            "
                        ),
                        _vm.custForm.village2 !== ""
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.custForm.village2 + ", ")),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.custForm.moo2 !== ""
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.custForm.moo2 + ", ")),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.custForm.soi2 !== ""
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.custForm.soi2 + ", ")),
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.custForm.road2 +
                                ", " +
                                _vm.custForm.subDistrict2 +
                                ", " +
                                _vm.custForm.district2 +
                                ", " +
                                _vm.custForm.province2 +
                                ", " +
                                _vm.custForm.zipCode2
                            )
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.subCustForm.contactNo1 === undefined &&
          _vm.subCustForm.mobileNo !== undefined
            ? _c("el-card", { attrs: { "body-style": "padding:0px;" } }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix header",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Customer Information")])]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "grid-container" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.subCustForm.mobileNo !== "",
                            expression: "subCustForm.mobileNo !== ''",
                          },
                        ],
                        staticClass: "grid-item",
                        staticStyle: { "padding-top": "8px" },
                      },
                      [_vm._v("Mobile No.")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.subCustForm.mobileNo !== "",
                            expression: "subCustForm.mobileNo !== ''",
                          },
                        ],
                        staticClass: "grid-item",
                        staticStyle: { "padding-top": "8px" },
                      },
                      [_vm._v(":")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.subCustForm.mobileNo !== "",
                            expression: "subCustForm.mobileNo !== ''",
                          },
                        ],
                        staticClass: "grid-item",
                        staticStyle: { "padding-top": "8px" },
                      },
                      [_vm._v(_vm._s(_vm.subCustForm.mobileNo))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v("Account ID"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [
                      _vm._v(_vm._s(_vm.subCustForm.accountID)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v("Name")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "grid-item" }, [_vm._v(":")]),
                    _vm._v(" "),
                    _vm.subCustForm.name !== ""
                      ? _c("div", { staticClass: "grid-item" }, [
                          _vm._v(_vm._s(_vm.subCustForm.name)),
                        ])
                      : _c("div", { staticClass: "grid-item" }, [_vm._v("-")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "grid-item",
                        staticStyle: { "padding-bottom": "8px" },
                      },
                      [_vm._v("Identification No.")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "grid-item",
                        staticStyle: { "padding-bottom": "8px" },
                      },
                      [_vm._v(":")]
                    ),
                    _vm._v(" "),
                    _vm.subCustForm.idNo !== ""
                      ? _c(
                          "div",
                          {
                            staticClass: "grid-item",
                            staticStyle: { "padding-bottom": "8px" },
                          },
                          [_vm._v(_vm._s(_vm.subCustForm.idNo))]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "grid-item",
                            staticStyle: { "padding-bottom": "8px" },
                          },
                          [_vm._v("-")]
                        ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.custForm.contactNo1 !== undefined &&
          _vm.subCustForm.mobileNo === undefined
            ? _c(
                "el-card",
                {
                  staticStyle: { "margin-top": "8px" },
                  attrs: { "body-style": "padding:0px;" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix header",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_c("span", [_vm._v("Agent Details")])]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "block" }, [
                    _c("div", { staticClass: "grid-container" }, [
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-top": "8px" },
                        },
                        [_vm._v("Agent ID")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-top": "8px" },
                        },
                        [_vm._v(":")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-top": "8px" },
                        },
                        [_vm._v(_vm._s(_vm.custForm.agentID))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-bottom": "8px" },
                        },
                        [_vm._v("Agent Name")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-bottom": "8px" },
                        },
                        [_vm._v(":")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-bottom": "8px" },
                        },
                        [_vm._v(_vm._s(_vm.custForm.agentName))]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.subCustForm.contactNo1 === undefined &&
          _vm.subCustForm.mobileNo !== undefined
            ? _c(
                "el-card",
                {
                  staticStyle: { "margin-top": "8px" },
                  attrs: { "body-style": "padding:0px;" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix header",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_c("span", [_vm._v("Agent Details")])]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "block" }, [
                    _c("div", { staticClass: "grid-container" }, [
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-top": "8px" },
                        },
                        [_vm._v("Agent ID")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-top": "8px" },
                        },
                        [_vm._v(":")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-top": "8px" },
                        },
                        [_vm._v(_vm._s(_vm.subCustForm.agentID))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-bottom": "8px" },
                        },
                        [_vm._v("Agent Name")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-bottom": "8px" },
                        },
                        [_vm._v(":")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          staticStyle: { "padding-bottom": "8px" },
                        },
                        [_vm._v(_vm._s(_vm.subCustForm.agentName))]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.planForm.lines !== undefined && _vm.planForm.lines.length > 0
            ? _c(
                "el-card",
                {
                  staticStyle: { "margin-top": "8px" },
                  attrs: { "body-style": "padding:0px;" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix header",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_c("span", [_vm._v("Line Details")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block", staticStyle: { padding: "18px" } },
                    [
                      _vm._l(_vm.planForm.lines, function (form, index) {
                        return _c("div", { key: index }, [
                          _c("div", { staticClass: "black-bar" }, [
                            _vm._v(
                              "Line " + _vm._s(index + 1) + "\n            "
                            ),
                            index == 0 && form.isMainLine
                              ? _c(
                                  "span",
                                  { staticStyle: { "padding-left": "18px" } },
                                  [
                                    _c("span", { staticClass: "master" }, [
                                      _vm._v("Master"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lineColumn box" }, [
                            _c("div", { staticClass: "grid-container-line" }, [
                              _c("div", [_vm._v("Mobile No.")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(form.mobileNo))]),
                              _vm._v(" "),
                              _c("div", [_vm._v("ICCID")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(form.iccid))]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Project Type")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(form.projectType))]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Plan")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(form.plan))]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Selected VAS")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              form.availableVas !== undefined
                                ? _c(
                                    "div",
                                    [
                                      _vm._l(
                                        form.availableVas,
                                        function (selectedVas, vasIndex) {
                                          return _c("div", { key: vasIndex }, [
                                            vasIndex !==
                                            form.availableVas.length - 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("truncate")(
                                                        selectedVas
                                                      )
                                                    ) + ","
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("truncate")(
                                                        selectedVas
                                                      )
                                                    )
                                                  ),
                                                ]),
                                          ])
                                        }
                                      ),
                                      _vm._v(" "),
                                      form.availableVas.length === 0
                                        ? _c("div", [_vm._v("None")])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", [_vm._v("Contract Period")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(form.contractMonth) + " Month(s)"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "grid-container-line" }, [
                              _c("div", { staticClass: "grid-item" }, [
                                _vm._v("Credit Limit"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "grid-item" }, [
                                _vm._v(":"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "grid-item" }, [
                                _vm._v("฿" + _vm._s(form.creditLimit)),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Sim Card Fee")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v("฿" + _vm._s(form.simCardFee)),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Registration Fee")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v("฿" + _vm._s(form.registrationFee)),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Deposit Amount")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v("฿" + _vm._s(form.depositAmount)),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Advance Payment")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(form.advancedPayment)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "grid-item",
                                  staticStyle: { "font-weight": "bold" },
                                },
                                [_vm._v("Upfront Payment")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "grid-item",
                                  staticStyle: { "font-weight": "bold" },
                                },
                                [_vm._v(":")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "grid-item",
                                  staticStyle: { "font-weight": "bold" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toCurrency")(form.upFrontPayment)
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "margin-top": "18px" } }),
                        ])
                      }),
                      _vm._v(" "),
                      _vm.planForm.lines !== undefined &&
                      _vm.planForm.lines.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "black-bar",
                              staticStyle: { "margin-top": "18px" },
                            },
                            [
                              _c("div", { staticClass: "tax-grid-container" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "padding-top": "0px",
                                      "text-align": "left",
                                      width: "150px",
                                    },
                                  },
                                  [_vm._v("Sub Total Payment")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: { "padding-top": "0px" },
                                  },
                                  [_vm._v(":")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: { "padding-top": "0px" },
                                  },
                                  [_vm._v("฿")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "padding-top": "0px",
                                      "padding-left": "25px",
                                      "text-align": "right",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.grandTotal.total.toFixed(2)
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "tax-grid-container" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "text-align": "left",
                                      width: "150px",
                                    },
                                  },
                                  [_vm._v("VAT 7%")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "grid-item" }, [
                                  _vm._v(":"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "grid-item" }, [
                                  _vm._v("฿"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "padding-left": "25px",
                                      "text-align": "right",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.grandTotal.tax.toFixed(2)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "tax-grid-container" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "text-align": "left",
                                      width: "150px",
                                    },
                                  },
                                  [_vm._v("Grand Total Payment")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "grid-item" }, [
                                  _vm._v(":"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "grid-item" }, [
                                  _vm._v("฿"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "padding-left": "25px",
                                      "text-align": "right",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          (
                                            _vm.grandTotal.total +
                                            _vm.grandTotal.tax
                                          ).toFixed(2)
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.planForm.mnpLines !== undefined &&
          _vm.planForm.mnpLines.length > 0
            ? _c(
                "el-card",
                {
                  staticStyle: { "margin-top": "8px" },
                  attrs: { "body-style": "padding:0px;" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix header",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _vm._v("\n        Line Details "),
                      _c("span", { staticStyle: { "font-weight": "500" } }, [
                        _vm._v("(MNP)"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block", staticStyle: { padding: "18px" } },
                    [
                      _vm._l(_vm.planForm.mnpLines, function (form, index) {
                        return _c("div", { key: index }, [
                          _c("div", { staticClass: "black-bar" }, [
                            _vm._v(
                              "Line " + _vm._s(index + 1) + "\n            "
                            ),
                            index == 0 && form.isMainLine
                              ? _c(
                                  "span",
                                  { staticStyle: { "padding-left": "18px" } },
                                  [
                                    _c("span", { staticClass: "master" }, [
                                      _vm._v("Master"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "lineColumn box" }, [
                            _c("div", { staticClass: "grid-container-line" }, [
                              _c("div", [_vm._v("Mobile No. to Port In")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(form.mobileNoPortIn))]),
                              _vm._v(" "),
                              _c("div", [_vm._v("ICCID")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(form.iccid))]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Project Type")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(form.projectType))]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Plan")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(form.plan))]),
                              _vm._v(" "),
                              form.availableVas !== undefined &&
                              form.availableVas.length !== 0
                                ? _c("div", [_vm._v("Selected VAS")])
                                : _vm._e(),
                              _vm._v(" "),
                              form.availableVas !== undefined &&
                              form.availableVas.length !== 0
                                ? _c("div", [_vm._v(":")])
                                : _vm._e(),
                              _vm._v(" "),
                              form.availableVas !== undefined &&
                              form.availableVas.length !== 0
                                ? _c(
                                    "div",
                                    _vm._l(
                                      form.availableVas,
                                      function (selectedVas, vasIndex) {
                                        return _c("div", { key: vasIndex }, [
                                          vasIndex !==
                                          form.availableVas.length - 1
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(selectedVas + ",")
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(_vm._s(selectedVas)),
                                              ]),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", [_vm._v("Contract Period")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(form.contractMonth) + " Month(s)"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "grid-container-line" }, [
                              _c("div", { staticClass: "grid-item" }, [
                                _vm._v("Existing Telco"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "grid-item" }, [
                                _vm._v(":"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "grid-item" }, [
                                _vm._v(_vm._s(form.existingTelco)),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Pin Code")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(form.pinCode))]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Credit Limit")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v("฿" + _vm._s(form.creditLimit)),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Sim Card Fee")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v("฿" + _vm._s(form.simCardFee)),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Registration Fee")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v("฿" + _vm._s(form.registrationFee)),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Deposit Amount")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v("฿" + _vm._s(form.depositAmount)),
                              ]),
                              _vm._v(" "),
                              _c("div", [_vm._v("Advance Payment")]),
                              _vm._v(" "),
                              _c("div", [_vm._v(":")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toCurrency")(form.advancedPayment)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "grid-item",
                                  staticStyle: { "font-weight": "bold" },
                                },
                                [_vm._v("Upfront Payment")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "grid-item",
                                  staticStyle: { "font-weight": "bold" },
                                },
                                [_vm._v(":")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "grid-item",
                                  staticStyle: { "font-weight": "bold" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toCurrency")(form.upFrontPayment)
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "margin-top": "18px" } }),
                        ])
                      }),
                      _vm._v(" "),
                      _vm.planForm.mnpLines !== undefined &&
                      _vm.planForm.mnpLines.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "black-bar",
                              staticStyle: { "margin-top": "18px" },
                            },
                            [
                              _c("div", { staticClass: "tax-grid-container" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "padding-top": "0px",
                                      "text-align": "left",
                                      width: "150px",
                                    },
                                  },
                                  [_vm._v("Sub Total Payment")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: { "padding-top": "0px" },
                                  },
                                  [_vm._v(":")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: { "padding-top": "0px" },
                                  },
                                  [_vm._v("฿")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "padding-top": "0px",
                                      "padding-left": "25px",
                                      "text-align": "right",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.grandTotal.total.toFixed(2)
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "tax-grid-container" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "text-align": "left",
                                      width: "150px",
                                    },
                                  },
                                  [_vm._v("VAT 7%")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "grid-item" }, [
                                  _vm._v(":"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "grid-item" }, [
                                  _vm._v("฿"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "padding-left": "25px",
                                      "text-align": "right",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.grandTotal.tax.toFixed(2)) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "tax-grid-container" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "text-align": "left",
                                      width: "150px",
                                    },
                                  },
                                  [_vm._v("Grand Total Payment")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "grid-item" }, [
                                  _vm._v(":"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "grid-item" }, [
                                  _vm._v("฿"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid-item",
                                    staticStyle: {
                                      "padding-left": "25px",
                                      "text-align": "right",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          (
                                            _vm.grandTotal.total +
                                            _vm.grandTotal.tax
                                          ).toFixed(2)
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticStyle: { "margin-top": "8px" },
              attrs: { "body-style": "padding:0px;" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix header",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_c("span", [_vm._v("Signature")])]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "block text" }, [
                _c(
                  "div",
                  {
                    staticClass: "row",
                    staticStyle: { margin: "auto", "text-align": "center" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "column-ic" },
                      [
                        _c("div", { staticClass: "blue-bar" }, [
                          _vm._v("Partner's Signature *"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "center" }, [
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showAlertSign,
                                  expression: "showAlertSign",
                                },
                              ],
                              staticClass: "alertSignMessage",
                            },
                            [_vm._v(_vm._s(_vm.alertSignMessage))]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "sign-box" }, [
                            _c("img", {
                              staticClass: "attachImage",
                              attrs: { src: _vm.imgDataUrl, height: "350" },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "center",
                            staticStyle: { "margin-top": "18px" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "black-button",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeSign()
                                  },
                                },
                              },
                              [_c("span", [_vm._v("REMOVE")])]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "blue-button",
                                attrs: {
                                  id: "snap",
                                  icon: "el-icon-upload",
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.attachImageShow = true
                                  },
                                },
                              },
                              [_c("span", [_vm._v("ATTACH")])]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("image-cropper", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.attachImageShow,
                              expression: "attachImageShow",
                            },
                          ],
                          key: _vm.attachImageKey,
                          attrs: {
                            width: 350,
                            height: 400,
                            url: "https://httpbin.org/post",
                            "lang-type": "en",
                          },
                          on: {
                            close: _vm.close,
                            "crop-success": _vm.cropSuccess,
                            "crop-upload-success": _vm.cropUploadSuccess,
                            "crop-upload-fail": _vm.cropUploadFail,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "column-ic" },
                      [
                        _c("div", { staticClass: "blue-bar" }, [
                          _vm._v("Customer's Signature *"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "center" }, [
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showAlertSignCust,
                                  expression: "showAlertSignCust",
                                },
                              ],
                              staticClass: "alertSignMessage",
                            },
                            [_vm._v(_vm._s(_vm.alertSignCustMessage))]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "sign-box" }, [
                            _c("img", {
                              staticClass: "attachImage",
                              attrs: { src: _vm.imgSignCust, height: "350" },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "center",
                            staticStyle: { "margin-top": "18px" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "black-button",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeSignCust()
                                  },
                                },
                              },
                              [_c("span", [_vm._v("REMOVE")])]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "blue-button",
                                attrs: {
                                  id: "snap",
                                  icon: "el-icon-upload",
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.attachImageShowCust = true
                                  },
                                },
                              },
                              [_c("span", [_vm._v("ATTACH")])]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("image-cropper", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.attachImageShowCust,
                              expression: "attachImageShowCust",
                            },
                          ],
                          key: _vm.attachImageKeyCust,
                          attrs: {
                            width: 350,
                            height: 400,
                            url: "https://httpbin.org/post",
                            "lang-type": "en",
                          },
                          on: {
                            close: _vm.closeCust,
                            "crop-success": _vm.cropSuccessCust,
                            "crop-upload-success": _vm.cropUploadSuccess,
                            "crop-upload-fail": _vm.cropUploadFail,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticStyle: { "margin-top": "8px" },
              attrs: { "body-style": "padding:0px;" },
            },
            [
              _c("div", { staticClass: "row block" }, [
                _c(
                  "div",
                  { staticStyle: { width: "3%", float: "left" } },
                  [_c("el-checkbox", { on: { change: _vm.checkTNC } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "checkboxText",
                    staticStyle: { width: "97%", float: "left" },
                  },
                  [
                    _vm._v(
                      "\n          I hereby confirm that I have read, understood, and agreed to the aforesaid Plans, and\n          "
                    ),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tncLoading,
                            expression: "tncLoading",
                          },
                        ],
                        staticClass: "tncLink",
                        on: {
                          click: function ($event) {
                            return _vm.OpenPDF()
                          },
                        },
                      },
                      [_vm._v("Terms and Conditions")]
                    ),
                    _vm._v(" in this Agreement.\n          "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showAlertTNC,
                            expression: "showAlertTNC",
                          },
                        ],
                        staticClass: "alertTNCMessage",
                      },
                      [_vm._v(_vm._s(_vm.alertTNCMessage))]
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("el-card", { staticStyle: { "margin-top": "18px" } }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "black-button",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.back()
                        },
                      },
                    },
                    [_vm._v("BACK")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading.body",
                          value: _vm.submitLoading,
                          expression: "submitLoading",
                          modifiers: { body: true },
                        },
                      ],
                      staticClass: "blue-button",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmSubmit()
                        },
                      },
                    },
                    [_vm._v("CONFIRM")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading.body",
            value: _vm.loading,
            expression: "loading",
            modifiers: { body: true },
          },
        ],
        staticClass: "filter-container",
      },
      [
        _vm.checkPermission(["ActivationSetting_Manage"])
          ? _c(
              "el-form",
              {
                ref: "addNumberCategoryForm",
                attrs: {
                  model: _vm.addNumberCategoryForm,
                  inline: true,
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("alert-list", {
                      attrs: { "alert-message": _vm.alertMessage },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "header-mixin",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("span", [_vm._v("Add Number Category:")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: {
                          "max-width": "450px",
                          "padding-top": "18px",
                        },
                        attrs: { prop: "mvneServiceCode" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Code" },
                          model: {
                            value: _vm.addNumberCategoryForm.mvneServiceCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.addNumberCategoryForm,
                                "mvneServiceCode",
                                $$v
                              )
                            },
                            expression: "addNumberCategoryForm.mvneServiceCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: {
                          "max-width": "450px",
                          "padding-top": "18px",
                        },
                        attrs: { prop: "description" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Description" },
                          model: {
                            value: _vm.addNumberCategoryForm.description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.addNumberCategoryForm,
                                "description",
                                $$v
                              )
                            },
                            expression: "addNumberCategoryForm.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticStyle: { "padding-top": "18px" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "5px" },
                            attrs: { type: "success", icon: "el-icon-plus" },
                            on: {
                              click: function ($event) {
                                return _vm.submitAdd()
                              },
                            },
                          },
                          [_vm._v("Add Number Category")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    ref: "listTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.resultList,
                      "element-loading-text": "Loading",
                      border: "",
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "#", width: "50" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [_vm._v(_vm._s(scope.$index + 1))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4178052834
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "Code",
                        prop: "numberCategory",
                        "min-width": "200",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.mvneServiceCode)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2026808838
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "Description",
                        prop: "description",
                        "min-width": "200",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.description)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1516911488
                      ),
                    }),
                    _vm._v(" "),
                    _vm.checkPermission(["ActivationSetting_Delete"])
                      ? _c("el-table-column", {
                          attrs: {
                            fixed: "right",
                            label: "Operation",
                            "min-width": "200",
                            width: "250",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.checkPermission([
                                      "ActivationSetting_Delete",
                                    ])
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              icon: "el-icon-delete",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteNumberCategoriesButton(
                                                  scope
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Delete")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            183574817
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
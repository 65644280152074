"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'CP Admin',
      logo: 'https://2.bp.blogspot.com/-kcuftrYVgps/V1Gn_O4W5KI/AAAAAAAAAUc/b1rIcGSWiCg-CPSxTmbEvSLAQkwLR4juwCLcB/s1600/img_redONE_online.png'
    };
  }
};
exports.default = _default;
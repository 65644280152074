var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c(
          "router-link",
          { attrs: { to: "/RegistrationSettings/caf/addCaf/" } },
          [
            _vm.checkPermission(["ActivationSetting_Manage"])
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      id: "button",
                      type: "success",
                      icon: "el-icon-plus",
                    },
                  },
                  [_vm._v("Add CAF")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            ref: "listTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.resultList,
              "element-loading-text": "Loading",
              border: "",
              fit: "",
              "highlight-current-row": "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "#", width: "50" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.$index + 1))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Version", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.version))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Project Type" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.projectType))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Start Date", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(
                              scope.row.startTimestamp,
                              "DD/MM/YYYY"
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "End Date", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(
                              scope.row.endTimestamp,
                              "DD/MM/YYYY"
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Active", width: "150", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.active === true
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: _vm.getActiveTagType(
                                      scope.row.active
                                    ),
                                  },
                                },
                                [_vm._v("\n              Active\n            ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.active === false
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: _vm.getActiveTagType(
                                      scope.row.active
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              Not Active\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Statement", width: "100", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading.body",
                              value: scope.row.loadingStatement,
                              expression: "scope.row.loadingStatement",
                              modifiers: { body: true },
                            },
                          ],
                          attrs: {
                            icon: "el-icon-printer",
                            size: "small",
                            type: "text",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.OpenPDF(
                                scope.row,
                                scope.row.id,
                                scope.row.projectType
                              )
                            },
                          },
                        },
                        [_vm._v("PDF")]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.checkPermission(["ActivationSetting_Manage"])
              ? _c("el-table-column", {
                  attrs: { label: "Operation", width: "200", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/RegistrationSettings/caf/editCaf/" +
                                    scope.row.id,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-edit",
                                      type: "text",
                                    },
                                  },
                                  [_vm._v("Edit")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1230865846
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true },
        },
      ],
      staticClass: "createPost-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "120px" },
        },
        [
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "750px" },
                  attrs: { prop: "name", label: "Name:" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isEdit
                    ? _c(
                        "el-col",
                        { attrs: { span: 2.1 } },
                        [
                          _vm._v("\n           "),
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: _vm.getStatusTagType(_vm.userStatus.code),
                              },
                            },
                            [_vm._v(_vm._s(_vm.userStatus.description))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isEdit && _vm.userStatus.code === 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 2.1 } },
                        [
                          _vm._v("\n           "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.disableButton()
                                },
                              },
                            },
                            [_vm._v("Disable User")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isEdit && _vm.userStatus.code === 2
                    ? _c(
                        "el-col",
                        { attrs: { span: 2.1 } },
                        [
                          _vm._v("\n           "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.enableButton()
                                },
                              },
                            },
                            [_vm._v("Enable User")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "username", label: "Username:" },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: {
                    prop: "email",
                    label: "Email:",
                    rules: [
                      {
                        required: true,
                        message: "Please input email address",
                        trigger: "blur",
                      },
                      {
                        type: "email",
                        message: "Please input correct email address",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.body",
                      value: _vm.groupListLoading,
                      expression: "groupListLoading",
                      modifiers: { body: true },
                    },
                  ],
                  attrs: { size: "small", prop: "groups", label: "Groups:" },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticStyle: { "max-width": "800px" },
                      model: {
                        value: _vm.form.groups,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "groups", $$v)
                        },
                        expression: "form.groups",
                      },
                    },
                    _vm._l(_vm.groupList, function (group) {
                      return _c(
                        "el-checkbox",
                        { key: group.id, attrs: { label: group.id } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(group.name) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _vm.isEdit
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitSave()
                            },
                          },
                        },
                        [_vm._v("Save")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitAdd()
                            },
                          },
                        },
                        [_vm._v("Create")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true },
        },
      ],
      staticClass: "createPost-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "addIdTypeForm",
          attrs: {
            model: _vm.addIdTypeForm,
            "label-width": "120px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c("alert-list", {
                attrs: { "alert-message": _vm.alertMessage },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "id", label: "ID" },
                },
                [
                  _c("el-input", {
                    on: { input: _vm.isNumber },
                    model: {
                      value: _vm.addIdTypeForm.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.addIdTypeForm, "id", _vm._n($$v))
                      },
                      expression: "addIdTypeForm.id",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "name", label: "Name" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addIdTypeForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.addIdTypeForm, "name", $$v)
                      },
                      expression: "addIdTypeForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "mvneIdTypeCode", label: "MVNE ID Code" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addIdTypeForm.mvneIdTypeCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.addIdTypeForm, "mvneIdTypeCode", $$v)
                      },
                      expression: "addIdTypeForm.mvneIdTypeCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitAdd()
                        },
                      },
                    },
                    [_vm._v("Create")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true },
        },
      ],
      staticClass: "createPost-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "billAdvertisementForm",
          attrs: { model: _vm.billAdvertisementForm, "label-width": "150px" },
        },
        [
          _c("alert-list", { attrs: { "alert-message": _vm.alertMessage } }),
          _vm._v(" "),
          _c("div", { staticClass: "table" }, [
            _c("div", { staticClass: "table-header" }, [
              _c("div", { staticClass: "header__item filter__link" }, [
                _vm._v("Advertisement Image 1"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "header__item filter__link" }, [
                _vm._v("Advertisement Image 2"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-content" }, [
              _c("div", { staticClass: "table-row" }, [
                _c("div", { staticClass: "table-data" }, [
                  _vm.live1src
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.liveImage1Loading,
                              expression: "liveImage1Loading",
                            },
                          ],
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "150px", height: "200px" },
                            attrs: {
                              src: _vm.live1src,
                              "preview-src-list": [_vm.live1src],
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.liveImage1Loading,
                              expression: "liveImage1Loading",
                            },
                          ],
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "150px", height: "200px" },
                            attrs: { src: _vm.nolivesrc },
                          }),
                        ]
                      ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "table-data" }, [
                  _vm.live2src
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.liveImage2Loading,
                              expression: "liveImage2Loading",
                            },
                          ],
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "150px", height: "200px" },
                            attrs: {
                              src: _vm.live2src,
                              "preview-src-list": [_vm.live2src],
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.liveImage2Loading,
                              expression: "liveImage2Loading",
                            },
                          ],
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "150px", height: "200px" },
                            attrs: { src: _vm.nolivesrc },
                          }),
                        ]
                      ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table" }, [
            _c("div", { staticClass: "table-header" }, [
              _c("div", { staticClass: "header__item filter__link" }, [
                _vm._v("Preview Image 1"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "header__item filter__link" }, [
                _vm._v("Preview Image 2"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-content" }, [
              _c("div", { staticClass: "table-row" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tempImage1Loading,
                        expression: "tempImage1Loading",
                      },
                    ],
                    staticClass: "table-data",
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "image1",
                        staticClass: "upload-image1",
                        attrs: {
                          action: "",
                          "http-request": _vm.AddAttachment1,
                          "on-change": _vm.addImageOne,
                          "on-preview": _vm.handlePreview,
                          "on-remove": _vm.handleRemove1,
                          "before-remove": _vm.beforeRemove,
                          multiple: "",
                          "auto-upload": true,
                          limit: 1,
                          "on-exceed": _vm.handleExceed,
                          "file-list": _vm.billAdvertisementForm.attachment1,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "medium", type: "primary" } },
                          [_vm._v("Upload Image")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            staticStyle: {
                              "margin-bottom": "20px",
                              "margin-top": "10px",
                            },
                            attrs: { slot: "tip" },
                            slot: "tip",
                          },
                          [_vm._v("files with a size less than 150kb")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.temp1src
                      ? _c(
                          "div",
                          [
                            _c("el-image", {
                              staticStyle: { width: "150px", height: "200px" },
                              attrs: {
                                src: _vm.temp1src,
                                "preview-src-list": [_vm.temp1src],
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: { size: "medium", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteAttachment1()
                                      },
                                    },
                                  },
                                  [_vm._v("Delete Image")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("div", [
                          _c("img", {
                            staticStyle: { width: "150px", height: "200px" },
                            attrs: { src: _vm.notempsrc },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: {
                                    size: "medium",
                                    type: "danger",
                                    disabled: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteAttachment1()
                                    },
                                  },
                                },
                                [_vm._v("Delete Image")]
                              ),
                            ],
                            1
                          ),
                        ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tempImage2Loading,
                        expression: "tempImage2Loading",
                      },
                    ],
                    staticClass: "table-data",
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "image2",
                        staticClass: "upload-image2",
                        attrs: {
                          action: "",
                          "http-request": _vm.AddAttachment2,
                          "on-change": _vm.addImageTwo,
                          "on-preview": _vm.handlePreview,
                          "on-remove": _vm.handleRemove2,
                          "before-remove": _vm.beforeRemove,
                          multiple: "",
                          "auto-upload": true,
                          limit: 1,
                          "on-exceed": _vm.handleExceed,
                          "file-list": _vm.billAdvertisementForm.attachment2,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "medium", type: "primary" } },
                          [_vm._v("Upload Image")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            staticStyle: {
                              "margin-bottom": "20px",
                              "margin-top": "10px",
                            },
                            attrs: { slot: "tip" },
                            slot: "tip",
                          },
                          [_vm._v("files with a size less than 150kb")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.temp2src
                      ? _c(
                          "div",
                          [
                            _c("el-image", {
                              staticStyle: { width: "150px", height: "200px" },
                              attrs: {
                                src: _vm.temp2src,
                                "preview-src-list": [_vm.temp2src],
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: { size: "medium", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteAttachment2()
                                      },
                                    },
                                  },
                                  [_vm._v("Delete Image")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("div", [
                          _c("img", {
                            staticStyle: { width: "150px", height: "200px" },
                            attrs: { src: _vm.notempsrc },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: {
                                    size: "medium",
                                    type: "danger",
                                    disabled: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteAttachment2()
                                    },
                                  },
                                },
                                [_vm._v("Delete Image")]
                              ),
                            ],
                            1
                          ),
                        ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-row" }, [
                _c(
                  "div",
                  { staticClass: "table-data" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { type: "info" },
                        on: {
                          click: function ($event) {
                            return _vm.OpenBillPreviewPDF()
                          },
                        },
                      },
                      [_vm._v("Preview")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.publishAdvertisement()
                          },
                        },
                      },
                      [_vm._v("Publish")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
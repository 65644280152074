"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constants = require("@/utils/constants");

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    alertMessage: {
      type: Object,
      default: function _default() {
        return {
          message: '',
          type: _constants.alertType.info,
          details: []
        };
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    showAlertMessage: function showAlertMessage() {
      return this.alertMessage.message !== '';
    }
  }
};
exports.default = _default2;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      camera01: {},
      canvas01: {},
      camera02: {},
      canvas02: {},
      isCameraOpen01: false,
      isPhotoTaken01: false,
      isCameraOpen02: false,
      isPhotoTaken02: false,
      localstream01: null,
      localstream02: null,
      icImage: '',
      faceImage: '',
      alertICMessage: '',
      alertFaceMessage: '',
      showAlertIC: false,
      showAlertFace: false
    };
  },
  mounted: function mounted() {
    this.canvas01 = this.$refs.canvas01;
    this.camera01 = this.$refs.camera01;
    this.canvas02 = this.$refs.canvas02;
    this.camera02 = this.$refs.camera02;
    this.canvasDoc = this.$refs.canvasDoc;
    this.cameraDoc = this.$refs.cameraDoc;
  },
  methods: {
    toggleCamera01: function toggleCamera01() {
      this.showAlertIC = false;

      if (this.isCameraOpen01) {
        this.isCameraOpen01 = false;
        this.isPhotoTaken01 = false;
        this.stopCameraStream01();
      } else {
        this.isCameraOpen01 = true;
        this.createCameraElement01();
      }
    },
    toggleCamera02: function toggleCamera02() {
      this.showAlertFace = false;

      if (this.isCameraOpen02) {
        this.isCameraOpen02 = false;
        this.isPhotoTaken02 = false;
        this.stopCameraStream02();
      } else {
        this.isCameraOpen02 = true;
        this.createCameraElement02();
      }
    },
    captureIC: function captureIC() {
      this.isPhotoTaken01 = !this.isPhotoTaken01;
      var videoRatio = this.camera01.videoWidth / this.camera01.videoHeight;
      var width = this.camera01.offsetWidth;
      var height = this.camera01.offsetHeight;
      var elementRatio = width / height;

      if (elementRatio > videoRatio) {
        width = height * videoRatio;
      } else {
        height = width / videoRatio;
      }

      this.canvas01.width = width;
      this.canvas01.height = height;
      var context = this.canvas01.getContext('2d');
      context.drawImage(this.camera01, 0, 0, width, height);
      this.canvas01.style.display = 'block';
      this.icImage = this.canvas01.toDataURL();
    },
    captureFace: function captureFace() {
      this.isPhotoTaken02 = !this.isPhotoTaken02;
      var videoRatio = this.camera02.videoWidth / this.camera02.videoHeight;
      var width = this.camera02.offsetWidth;
      var height = this.camera02.offsetHeight;
      var elementRatio = width / height;

      if (elementRatio > videoRatio) {
        width = height * videoRatio;
      } else {
        height = width / videoRatio;
      }

      this.canvas02.width = width;
      this.canvas02.height = height;
      var context = this.canvas02.getContext('2d');
      context.drawImage(this.camera02, 0, 0, width, height);
      this.canvas02.style.display = 'block';
      this.faceImage = this.canvas02.toDataURL();
    },
    createCameraElement01: function createCameraElement01() {
      var _this = this;

      var constraints = window.constraints = {
        audio: false,
        video: {
          width: 1280,
          height: 720
        }
      };

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
          _this.camera01.srcObject = stream;
          _this.localstream01 = stream;

          _this.camera01.play();
        }).catch(function (Error) {
          alert("May the browser didn't support or there is some errors:" + Error);
        });
      }
    },
    createCameraElement02: function createCameraElement02() {
      var _this2 = this;

      var constraints = window.constraints = {
        audio: false,
        video: {
          width: 1280,
          height: 720
        }
      };

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
          _this2.camera02.srcObject = stream;
          _this2.localstream02 = stream;

          _this2.camera02.play();
        }).catch(function (Error) {
          alert("May the browser didn't support or there is some errors:" + Error);
        });
      }
    },
    stopCameraStream01: function stopCameraStream01() {
      if (this.localstream01 !== undefined) {
        this.camera01.pause();
        this.camera01.src = '';
        this.localstream01.getTracks()[0].stop();
        console.log('IC Front Video off');
      }
    },
    stopCameraStream02: function stopCameraStream02() {
      if (this.localstream02 !== undefined) {
        this.camera02.pause();
        this.camera02.src = '';
        this.localstream02.getTracks()[0].stop();
        console.log('IC Back Video off');
      }
    },
    closeModal: function closeModal() {
      this.$router.push({
        name: 'ActivationNew',
        params: {}
      });
    },
    verify: function verify() {
      if (this.icImage === '' || this.faceImage === '') {
        this.alertICMessage = 'Please snap IC Photo';
        this.alertFaceMessage = 'Please snap Face Photo';
        this.showAlertIC = true;
        this.showAlertFace = true;
      } else {
        this.$message({
          type: 'success',
          message: 'Verified Successfully',
          duration: 5000,
          showClose: true
        });
        this.$router.push({
          name: 'ActivationNew',
          params: {}
        });
      }
    }
  }
};
exports.default = _default;
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "errPage-container" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h1", { staticClass: "text-jumbo text-ginormous" }, [
              _vm._v("Oops!"),
            ]),
            _vm._v(" "),
            _c("h2", [_vm._v("You are not authorized to visit this page")]),
            _vm._v(" "),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", [_vm._v("Navigate To:")]),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "link-type" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "padding-top": "0px" },
                      attrs: { type: "text" },
                      on: { click: _vm.login },
                    },
                    [_vm._v("Home")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "link-type" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.logout } },
                    [_vm._v("Login with another user")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("img", {
              attrs: {
                src: _vm.errGif,
                width: "313",
                height: "428",
                alt: "Girl has dropped her ice cream.",
              },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "随便看", visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [_c("img", { staticClass: "pan-img", attrs: { src: _vm.ewizardClap } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _user = require("@/api/user");

var _auth = require("@/utils/auth");

var _authService = require("@/utils/authService");

var _router = require("@/router");

// const _avatar = 'https://c8.alamy.com/comp/T7KMF8/isolated-cute-avatar-of-a-lion-T7KMF8.jpg'
var _avatar = '/avatar.jpg';

var getDefaultState = function getDefaultState() {
  return {
    // token: getToken(),
    profile: null,
    avatar: '',
    roles: []
  };
};

var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_PROFILE: function SET_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  }
};
var actions = {
  // get user info
  getInfo: function getInfo(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)().then(function (response) {
        var data = response.data;

        if (!data) {
          reject('Verification failed, please Login again.');
        }

        var accesses = data.accesses;
        var accessName = [];

        for (var i in accesses) {
          accessName.push(accesses[i].name);
        }

        commit('SET_ROLES', accessName);
        commit('SET_PROFILE', data);
        commit('SET_AVATAR', _avatar);
        resolve(data);
      }).catch(function (error) {
        console.log('get info error');
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      (0, _auth.clearToken)();
      (0, _authService.logout)().then(function () {
        (0, _router.resetRouter)();
        commit('RESET_STATE');
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;
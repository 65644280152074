"use strict";

module.exports = {
  title: process.env.VUE_APP_TITLE,
  // 'Thailand redONE Control Panel',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true
};
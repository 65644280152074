var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-editor-container" }, [
    _c("div", { staticClass: " clearfix" }),
    _vm._v(" "),
    _c("div", [
      _c("img", { staticClass: "emptyGif", attrs: { src: _vm.emptyGif } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c(
          "div",
          { staticClass: "row", staticStyle: { "margin-bottom": "8px" } },
          [
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "150px" },
                attrs: {
                  placeholder: "Filter by Alert Urgency",
                  loading: _vm.urgencyLoading,
                  filterable: "",
                  "loading-text": "",
                },
                on: { change: _vm.chooseUrgency },
                model: {
                  value: _vm.filterUrgency,
                  callback: function ($$v) {
                    _vm.filterUrgency = $$v
                  },
                  expression: "filterUrgency",
                },
              },
              [
                _c("el-option", {
                  key: "",
                  attrs: { value: "", label: "All" },
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "true",
                  attrs: { value: "true", label: "High Urgency" },
                }),
                _vm._v(" "),
                _c("el-option", {
                  key: "false",
                  attrs: { value: "false", label: "Low Urgency" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "350px" },
                attrs: {
                  placeholder: "Alert Type",
                  loading: _vm.typeLoading,
                  filterable: "",
                  "loading-text": "",
                  clearable: "",
                },
                on: { change: _vm.chooseType },
                model: {
                  value: _vm.filterType,
                  callback: function ($$v) {
                    _vm.filterType = $$v
                  },
                  expression: "filterType",
                },
              },
              _vm._l(_vm.typeList, function (item) {
                return _c("el-option", {
                  key: item.code,
                  attrs: { value: item.code, label: item.name },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("el-date-picker", {
              staticClass: "filter-item",
              attrs: {
                type: "daterange",
                align: "right",
                "unlink-panels": "",
                "range-separator": "To",
                "start-placeholder": "Start date",
                "end-placeholder": "End date",
                "picker-options": _vm.pickerOptions,
                value: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
              },
              on: { change: _vm.onChangeInput },
              model: {
                value: _vm.dateValue,
                callback: function ($$v) {
                  _vm.dateValue = $$v
                },
                expression: "dateValue",
              },
            }),
            _vm._v(" "),
            _c(
              "badge",
              { staticClass: "item", attrs: { "is-dot": _vm.isDot } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    staticStyle: { "margin-left": "5px" },
                    attrs: { type: "primary", icon: "el-icon-search" },
                    on: { click: _vm.searchClick },
                  },
                  [_vm._v("\n          Search\n        ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-checkbox", {
              staticStyle: { "padding-left": "20px" },
              attrs: { label: "Show Acknowledged Alert Too" },
              on: { change: _vm.checkAcknowledged },
              model: {
                value: _vm.isChecked,
                callback: function ($$v) {
                  _vm.isChecked = $$v
                },
                expression: "isChecked",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading.body",
                value: _vm.listLoading,
                expression: "listLoading",
                modifiers: { body: true },
              },
            ],
            ref: "listTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.alertList,
              "element-loading-text": "Loading",
              border: "",
              fit: "",
              "highlight-current-row": "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { fixed: "left", label: "#", width: "40" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.getRecordNumber(scope.$index))),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Alert Type", width: "310" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.alertType.name))]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Alert Info", width: "390" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.alertDetails))]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "First Alert Date", width: "95" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.firstAlertDate !== null
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  scope.row.firstAlertDate,
                                  "DD/MM/YYYY hh:mm:ssa"
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Last Alert Date", width: "95" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.lastAlertDate !== null
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(
                                  scope.row.lastAlertDate,
                                  "DD/MM/YYYY hh:mm:ssa"
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Remarks" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      row.acknowledgedBy === null
                        ? _c("el-input", {
                            attrs: { size: "small" },
                            model: {
                              value: row.remarks,
                              callback: function ($$v) {
                                _vm.$set(row, "remarks", $$v)
                              },
                              expression: "row.remarks",
                            },
                          })
                        : _c("span", { attrs: { size: "small" } }, [
                            _vm._v(_vm._s(row.remarks)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.checkPermission(["Alert_Manage"])
              ? _c("el-table-column", {
                  attrs: { label: "Operation", width: "150", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.acknowledgedBy === null
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkAlert(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Acknowledge")]
                                )
                              : _c("span", { staticClass: "isAcknowledged" }, [
                                  _vm._v("Acknowledged"),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1854202460
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.totalRecords > 0,
              expression: "totalRecords > 0",
            },
          ],
          attrs: { total: _vm.totalRecords, page: _vm.page, limit: _vm.limit },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "update:limit": function ($event) {
              _vm.limit = $event
            },
            pagination: _vm.pageInfoChanged,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
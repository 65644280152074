var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", [
    _c(
      "div",
      {
        staticClass: "clearfix header",
        attrs: { slot: "header" },
        slot: "header",
      },
      [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
        _c(
          "div",
          {
            staticStyle: { float: "right", padding: "3px 0" },
            attrs: { type: "text" },
          },
          [
            !_vm.expandMore
              ? _c("i", { staticClass: "el-icon-plus custom-expand" })
              : _c("i", { staticClass: "el-icon-minus custom-expand" }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c("el-input", {
          staticClass: "filter-item",
          staticStyle: { width: "400px" },
          attrs: { placeholder: "Search", clearable: "" },
          on: { input: _vm.onChangeInput },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.searchClick($event)
            },
          },
          model: {
            value: _vm.filterText,
            callback: function ($$v) {
              _vm.filterText = $$v
            },
            expression: "filterText",
          },
        }),
        _vm._v(" "),
        _c(
          "badge",
          { staticClass: "item", attrs: { "is-dot": _vm.isDot } },
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { "margin-left": "5px" },
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.searchClick },
              },
              [_vm._v("\n        Search\n      ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.checkPermission(["ActivationSetting_Manage"])
          ? _c(
              "router-link",
              { attrs: { to: "/RegistrationSettings/packages/add" } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      id: "button",
                      type: "success",
                      icon: "el-icon-plus",
                    },
                  },
                  [_vm._v("Add Package")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            ref: "listTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.resultList,
              "element-loading-text": "Loading",
              border: "",
              fit: "",
              "highlight-current-row": "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { fixed: "left", label: "#", width: "50" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getRecordNumber(scope.$index)) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { fixed: "left", label: "Code", width: "140" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.code))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Name", "min-width": "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "MVNE Package ID",
                "min-width": "148",
                width: "148",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.mvnePackageId))]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "Credit Limit",
                "min-width": "120",
                width: "120",
                align: "right",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.creditLimit))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "Deposit",
                "min-width": "120",
                width: "120",
                align: "right",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.deposit))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "Contract Month",
                "min-width": "128",
                width: "128",
                align: "right",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.contractMonth))]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "Advance Payment",
                "min-width": "150",
                width: "150",
                align: "right",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.advancePaymentWithoutTax)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.checkPermission([
              "ActivationSetting_Manage",
              "ActivationSetting_Delete",
            ])
              ? _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    label: "Operation",
                    width: "180",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.checkPermission(["ActivationSetting_Manage"])
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/RegistrationSettings/packages/edit/" +
                                        scope.row.code,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          icon: "el-icon-edit",
                                          type: "text",
                                        },
                                      },
                                      [_vm._v("Edit")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.checkPermission(["ActivationSetting_Delete"])
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-delete",
                                      type: "text",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deletePackageButton(scope)
                                      },
                                    },
                                  },
                                  [_vm._v("Delete")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2984898280
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.totalRecords > 0,
              expression: "totalRecords>0",
            },
          ],
          attrs: { total: _vm.totalRecords, page: _vm.page, limit: _vm.limit },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "update:limit": function ($event) {
              _vm.limit = $event
            },
            pagination: _vm.pageInfoChanged,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
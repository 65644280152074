var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c(
          "div",
          { staticClass: "row", staticStyle: { "margin-bottom": "8px" } },
          [
            _c("el-date-picker", {
              staticClass: "filter-item",
              attrs: {
                type: "daterange",
                align: "right",
                "unlink-panels": "",
                "range-separator": "To",
                "start-placeholder": "Start date",
                "end-placeholder": "End date",
                "picker-options": _vm.pickerOptions,
                value: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
              },
              on: { change: _vm.onChangeInput },
              model: {
                value: _vm.dateValue,
                callback: function ($$v) {
                  _vm.dateValue = $$v
                },
                expression: "dateValue",
              },
            }),
            _vm._v(" "),
            _c(
              "el-select",
              {
                staticClass: "filter-item",
                staticStyle: { width: "120px" },
                attrs: {
                  placeholder: "Status",
                  loading: _vm.statusLoading,
                  filterable: "",
                  "loading-text": "",
                  clearable: "",
                },
                on: { change: _vm.onChangeInput },
                model: {
                  value: _vm.filterStatus,
                  callback: function ($$v) {
                    _vm.filterStatus = $$v
                  },
                  expression: "filterStatus",
                },
              },
              _vm._l(_vm.statusList, function (item) {
                return _c("el-option", {
                  key: item.code,
                  attrs: { value: String(item.code), label: item.description },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { placeholder: "Mobile No.", clearable: "" },
              on: { input: _vm.onChangeInput },
              model: {
                value: _vm.filterMobileNo,
                callback: function ($$v) {
                  _vm.filterMobileNo = $$v
                },
                expression: "filterMobileNo",
              },
            }),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { placeholder: "Agent ID", clearable: "" },
              on: { input: _vm.onChangeInput },
              model: {
                value: _vm.filterAgentId,
                callback: function ($$v) {
                  _vm.filterAgentId = $$v
                },
                expression: "filterAgentId",
              },
            }),
            _vm._v(" "),
            _c(
              "badge",
              { staticClass: "item", attrs: { "is-dot": _vm.isDot } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    staticStyle: { "margin-left": "5px" },
                    attrs: { type: "primary", icon: "el-icon-search" },
                    on: { click: _vm.searchClick },
                  },
                  [_vm._v("\n          Search\n        ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading.body",
                value: _vm.listLoading,
                expression: "listLoading",
                modifiers: { body: true },
              },
            ],
            ref: "listTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.simReplacementList,
              "element-loading-text": "Loading",
              border: "",
              fit: "",
              "highlight-current-row": "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { fixed: "left", label: "#", width: "50" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.getRecordNumber(scope.$index))),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { fixed: "left", label: "Mobile No.", width: "120" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(row.mobileNo) +
                            "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Old ICCID", width: "160" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(row.oldIccId) +
                            "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "New ICCID", width: "160" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(row.newIccId) +
                            "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { align: "center", label: "Status", width: "95" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            type: _vm.getStatusTagType(scope.row.status.code),
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.status.description) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Remark" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(row.remarks) +
                            "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Result", width: "260" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(row.resultRemarks) +
                            "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Created Date", width: "110" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("moment")(
                              scope.row.createdDate,
                              "DD/MM/YYYY"
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Created By", width: "145" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.createdBy))])]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.totalRecords > 0,
              expression: "totalRecords > 0",
            },
          ],
          attrs: { total: _vm.totalRecords, page: _vm.page, limit: _vm.limit },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "update:limit": function ($event) {
              _vm.limit = $event
            },
            pagination: _vm.pageInfoChanged,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
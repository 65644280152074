var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icons-container" },
    [
      _c(
        "el-tabs",
        { ref: "tabMain", attrs: { type: "border-card" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Customer Details", disabled: "" } },
            [
              _c("customer-detail", {
                attrs: { tab: _vm.tab },
                on: {
                  custsaid: function ($event) {
                    return _vm.next($event)
                  },
                  custsubsaid: function ($event) {
                    return _vm.subNext($event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Attachment Details", disabled: "" } },
            [
              _c("attachment-detail", {
                attrs: { tab: _vm.tab },
                on: {
                  attachmentsaid: function ($event) {
                    return _vm.nextPlan($event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Plan and Mobile Details", disabled: "" } },
            [
              _c("plan-and-mobile-detail", {
                attrs: {
                  tab: _vm.tab,
                  "cust-form": _vm.custForm,
                  "sub-cust-form": _vm.subCustForm,
                },
                on: {
                  plansaid: function ($event) {
                    return _vm.nextConfirm($event)
                  },
                  plansaidtotal: function ($event) {
                    return _vm.nextTotal($event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Confirmation Details", disabled: "" } },
            [
              _c("confirmation-detail", {
                attrs: {
                  tab: _vm.tab,
                  "cust-form": _vm.custForm,
                  "sub-cust-form": _vm.subCustForm,
                  "attach-images": _vm.attachImages,
                  "plan-form": _vm.planForm,
                  "grand-total": _vm.grandTotal,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
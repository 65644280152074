var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "modal-content" },
      [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c(
              "el-button",
              {
                staticClass: "close",
                on: {
                  click: function ($event) {
                    return _vm.closeModal()
                  },
                },
              },
              [_vm._v("×")]
            ),
            _vm._v(" "),
            _c("h3", [_vm._v("Authenticate Face")]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row",
            staticStyle: { margin: "auto", "text-align": "center" },
          },
          [
            _c("div", { staticClass: "column-ic" }, [
              _c("div", { staticClass: "blue-bar" }, [
                _vm._v("Identification Card"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center ic-box" }, [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAlertIC,
                        expression: "showAlertIC",
                      },
                    ],
                    staticClass: "alertMessage",
                  },
                  [_vm._v(_vm._s(_vm.alertICMessage))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCameraOpen01,
                        expression: "isCameraOpen01",
                      },
                    ],
                    staticClass: "ic-image-box",
                  },
                  [
                    _c("video", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isPhotoTaken01,
                          expression: "!isPhotoTaken01",
                        },
                      ],
                      ref: "camera01",
                      attrs: {
                        id: "camera01",
                        width: "350",
                        height: "400",
                        autoplay: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("canvas", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPhotoTaken01,
                          expression: "isPhotoTaken01",
                        },
                      ],
                      ref: "canvas01",
                      attrs: { id: "photoTaken01" },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "center",
                  staticStyle: { "margin-top": "18px" },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCameraOpen01,
                          expression: "isCameraOpen01",
                        },
                      ],
                      staticClass: "camera-shoot",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.captureIC()
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-camera" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      class: {
                        "is-primary": !_vm.isCameraOpen01,
                        "is-danger": _vm.isCameraOpen01,
                      },
                      attrs: { id: "snap", type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleCamera01()
                        },
                      },
                    },
                    [
                      !_vm.isCameraOpen01
                        ? _c("span", [_vm._v("SNAP PHOTO")])
                        : _c("span", [_vm._v("CLOSE CAMERA")]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "column-ic" }, [
              _c("div", { staticClass: "blue-bar" }, [
                _vm._v("Customer's Face Photo"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center ic-box" }, [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAlertFace,
                        expression: "showAlertFace",
                      },
                    ],
                    staticClass: "alertMessage",
                  },
                  [_vm._v(_vm._s(_vm.alertFaceMessage))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCameraOpen02,
                        expression: "isCameraOpen02",
                      },
                    ],
                    staticClass: "ic-image-box",
                  },
                  [
                    _c("video", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isPhotoTaken02,
                          expression: "!isPhotoTaken02",
                        },
                      ],
                      ref: "camera02",
                      attrs: {
                        id: "camera02",
                        width: "350",
                        height: "400",
                        autoplay: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("canvas", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPhotoTaken02,
                          expression: "isPhotoTaken02",
                        },
                      ],
                      ref: "canvas02",
                      attrs: { id: "photoTaken02" },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "center",
                  staticStyle: { "margin-top": "18px" },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCameraOpen02,
                          expression: "isCameraOpen02",
                        },
                      ],
                      staticClass: "camera-shoot",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.captureFace()
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-camera" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      class: {
                        "is-primary": !_vm.isCameraOpen02,
                        "is-danger": _vm.isCameraOpen02,
                      },
                      attrs: { id: "snap", type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleCamera02()
                        },
                      },
                    },
                    [
                      !_vm.isCameraOpen02
                        ? _c("span", [_vm._v("SNAP PHOTO")])
                        : _c("span", [_vm._v("CLOSE CAMERA")]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "verifyButton",
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.verify()
              },
            },
          },
          [_vm._v("VERIFY")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c(
          "router-link",
          {
            attrs: {
              to: "/RegistrationSettings/identificationTypes/addIdType",
            },
          },
          [
            _vm.checkPermission(["ActivationSetting_Manage"])
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      id: "button",
                      type: "success",
                      icon: "el-icon-plus",
                    },
                  },
                  [_vm._v("Add Identification Types")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            ref: "listTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.resultList,
              "element-loading-text": "Loading",
              border: "",
              fit: "",
              "highlight-current-row": "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { fixed: "left", label: "ID", width: "50" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Name", "min-width": "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "MVNE ID Code", "min-width": "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.mvneIdTypeCode))]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.checkPermission([
              "ActivationSetting_Manage",
              "ActivationSetting_Delete",
            ])
              ? _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    label: "Operation",
                    "min-width": "200",
                    width: "250",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.checkPermission(["ActivationSetting_Manage"])
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/RegistrationSettings/identificationTypes/editIdType/" +
                                        scope.row.id,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          icon: "el-icon-edit",
                                          type: "text",
                                        },
                                      },
                                      [_vm._v("Edit")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.checkPermission(["ActivationSetting_Delete"])
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-delete",
                                      type: "text",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteIdentificationTypeButton(
                                          scope
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Delete")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1962615293
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
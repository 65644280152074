var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("alert-list", { attrs: { "alert-message": _vm.alertMessage } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "180px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Account Type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeAccountType },
                      model: {
                        value: _vm.accountTypes,
                        callback: function ($$v) {
                          _vm.accountTypes = $$v
                        },
                        expression: "accountTypes",
                      },
                    },
                    [
                      _c("el-radio-button", {
                        attrs: {
                          label: "New Activation",
                          "aria-checked": "true",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-radio-button", {
                        attrs: { label: "Add Subline" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSubline,
                expression: "isSubline",
              },
            ],
            staticClass: "sub-blue-line",
          }),
          _vm._v(" "),
          _vm.isSubline
            ? _c(
                "div",
                { staticStyle: { "margin-top": "18px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "sub_form",
                      attrs: {
                        model: _vm.sub_form,
                        rules: _vm.sub_rules,
                        "label-width": "180px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "max-width": "550px" },
                          attrs: { prop: "searchType", label: "Search by" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.searchTypesLoading,
                                  expression: "searchTypesLoading",
                                },
                              ],
                              ref: "select",
                              attrs: {
                                placeholder: "Please select search type",
                              },
                              on: { change: _vm.changeSearchType },
                              model: {
                                value: _vm.sub_form.searchType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sub_form, "searchType", $$v)
                                },
                                expression: "sub_form.searchType",
                              },
                            },
                            _vm._l(_vm.searchTypes, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: {
                                  value: item.code,
                                  label: item.description,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.sub_form.searchType === "mobileno"
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "max-width": "450px",
                                float: "left",
                                "margin-bottom": "0px",
                              },
                              attrs: { prop: "mobileNo", label: "Mobile No." },
                            },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "11" },
                                on: { input: _vm.isContactNumber },
                                model: {
                                  value: _vm.sub_form.mobileNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.sub_form, "mobileNo", $$v)
                                  },
                                  expression: "sub_form.mobileNo",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.sub_form.searchType === "account"
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                "max-width": "450px",
                                float: "left",
                                "margin-bottom": "0px",
                              },
                              attrs: {
                                prop: "searchAccountID",
                                label: "Account ID",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.sub_form.searchAccountID,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.sub_form,
                                      "searchAccountID",
                                      $$v
                                    )
                                  },
                                  expression: "sub_form.searchAccountID",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.sub_form.searchType !== null
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading.body",
                                  value: _vm.searchAccountLoading,
                                  expression: "searchAccountLoading",
                                  modifiers: { body: true },
                                },
                              ],
                              staticClass: "searchButton",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.search()
                                },
                              },
                            },
                            [_vm._v("Search")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "180px" },
        },
        [
          _c(
            "el-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isSubline,
                  expression: "!isSubline",
                },
              ],
              attrs: { "body-style": "padding:0px;" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix header",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_c("span", [_vm._v("Verification")])]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block text" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "max-width": "550px",
                        "padding-top": "18px",
                      },
                      attrs: { prop: "customerType", label: "Customer Type" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.custTypesLoading,
                              expression: "custTypesLoading",
                            },
                          ],
                          ref: "select",
                          staticStyle: { width: "350px" },
                          attrs: {
                            placeholder:
                              "Please select one of the customer type",
                          },
                          model: {
                            value: _vm.form.customerType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "customerType", $$v)
                            },
                            expression: "form.customerType",
                          },
                        },
                        _vm._l(_vm.custTypes, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "max-width": "550px" },
                      attrs: {
                        prop: "identificationType",
                        label: "Identification Type",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.idTypesLoading,
                              expression: "idTypesLoading",
                            },
                          ],
                          ref: "select",
                          staticStyle: { width: "350px" },
                          attrs: {
                            placeholder:
                              "Please select one of the identification type",
                          },
                          on: { change: _vm.changeIdentificationType },
                          model: {
                            value: _vm.form.identificationType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "identificationType", $$v)
                            },
                            expression: "form.identificationType",
                          },
                        },
                        _vm._l(_vm.idTypes, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "max-width": "450px", float: "left" },
                      attrs: { prop: "idNo", label: "Identification No." },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.idNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "idNo", $$v)
                          },
                          expression: "form.idNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading.body",
                          value: _vm.loading,
                          expression: "loading",
                          modifiers: { body: true },
                        },
                      ],
                      staticClass: "verifyButton",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.verify()
                        },
                      },
                    },
                    [_vm._v("\n          Confirm\n        ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hideAll && !_vm.isSubline,
                  expression: "!hideAll && !isSubline",
                },
              ],
              staticStyle: { "margin-top": "18px" },
            },
            [
              _c("div", { staticClass: "copy-el-card is-always-shadow" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix header copy-el-card-header",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("Customer Information")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block text" },
                  [
                    _vm.isBusiness
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  "max-width": "450px",
                                  "padding-top": "18px",
                                },
                                attrs: {
                                  prop: "companyName",
                                  label: "Company Name",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "companyName", $$v)
                                    },
                                    expression: "form.companyName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "max-width": "450px" },
                                attrs: { prop: "taxID", label: "Tax ID" },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.taxID,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "taxID", $$v)
                                    },
                                    expression: "form.taxID",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "block",
                                staticStyle: { "margin-bottom": "8px" },
                              },
                              [
                                _c("div", { staticClass: "blue-bar" }, [
                                  _vm._v("Authorised Details"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "blue-box" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: {
                                          "max-width": "450px",
                                          "padding-top": "18px",
                                        },
                                        attrs: {
                                          prop: "name",
                                          label: "Authorised Name",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: _vm.form.name,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "name", $$v)
                                            },
                                            expression: "form.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "max-width": "450px" },
                                        attrs: {
                                          prop: "contactNo1",
                                          label: "Authorised Contact No.",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { maxlength: "11" },
                                          on: { input: _vm.isContactNumber },
                                          model: {
                                            value: _vm.form.contactNo1,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "contactNo1",
                                                $$v
                                              )
                                            },
                                            expression: "form.contactNo1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isBusiness
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              "max-width": "450px",
                              "padding-top": "18px",
                            },
                            attrs: { prop: "name", label: "Name" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "name", $$v)
                                },
                                expression: "form.name",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "max-width": "550px" },
                        attrs: { prop: "salutation", label: "Salutation" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.salutationLoading,
                                expression: "salutationLoading",
                              },
                            ],
                            ref: "select",
                            staticStyle: { width: "350px" },
                            attrs: {
                              placeholder:
                                "Please select one of the salutations",
                            },
                            model: {
                              value: _vm.form.salutation,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "salutation", $$v)
                              },
                              expression: "form.salutation",
                            },
                          },
                          _vm._l(_vm.salutations, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "max-width": "550px" },
                        attrs: { prop: "nationality", label: "Nationality" },
                      },
                      [
                        _c("el-autocomplete", {
                          ref: "autocomplete",
                          staticStyle: { width: "350px" },
                          attrs: {
                            "fetch-suggestions": _vm.querySearchNationality,
                            placeholder: "Please select nationalities",
                          },
                          model: {
                            value: _vm.form.nationality,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "nationality", $$v)
                            },
                            expression: "form.nationality",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "max-width": "530px" },
                        attrs: {
                          prop: "verificationPhase",
                          label: "Place of Birth (Province)",
                        },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.verificationPhase,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "verificationPhase", $$v)
                            },
                            expression: "form.verificationPhase",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "max-width": "550px" },
                        attrs: { prop: "date", label: "DOB" },
                      },
                      [
                        _c("birth-datepicker", {
                          staticStyle: { "min-width": "15em" },
                          attrs: {
                            "max-year": _vm.getCurrentYear(),
                            "min-year": 1931,
                            "year-first": true,
                            delimiter: "-",
                            placeholder: "Click to Select Date",
                          },
                          model: {
                            value: _vm.form.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "date", $$v)
                            },
                            expression: "form.date",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "gender", label: "Gender" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticStyle: { "padding-left": "10px" },
                            model: {
                              value: _vm.form.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "gender", $$v)
                              },
                              expression: "form.gender",
                            },
                          },
                          _vm._l(_vm.genders, function (item) {
                            return _c("el-radio", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "language",
                          label: "Preferred Language",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticStyle: { "padding-left": "10px" },
                            model: {
                              value: _vm.form.language,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "language", $$v)
                              },
                              expression: "form.language",
                            },
                          },
                          _vm._l(_vm.languages, function (item) {
                            return _c("el-radio", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "dataConsent", label: "Data Consent" } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            staticStyle: {
                              "padding-left": "10px",
                              "max-width": "550px",
                            },
                            model: {
                              value: _vm.form.dataConsent,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dataConsent", $$v)
                              },
                              expression: "form.dataConsent",
                            },
                          },
                          _vm._l(_vm.dataConsents, function (item) {
                            return _c("el-checkbox", {
                              key: item.id,
                              attrs: {
                                checked: "",
                                label: item.name,
                                value: item.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSubline,
                      expression: "!isSubline",
                    },
                  ],
                  staticStyle: { "margin-top": "18px" },
                  attrs: { "body-style": "padding:0px;" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix header",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_vm._v("\n          Contact Details\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block text" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: {
                            "padding-top": "18px",
                            "max-width": "500px",
                          },
                          attrs: {
                            prop: "addressLineNo1",
                            label: "Present Address No.",
                          },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.addressLineNo1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "addressLineNo1", $$v)
                              },
                              expression: "form.addressLineNo1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: {
                            prop: "village1",
                            label: "Village/Building",
                          },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.village1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "village1", $$v)
                              },
                              expression: "form.village1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "moo1", label: "Moo" },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.moo1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "moo1", $$v)
                              },
                              expression: "form.moo1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "soi1", label: "Soi" },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.soi1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "soi1", $$v)
                              },
                              expression: "form.soi1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "road1", label: "Road" },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.road1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "road1", $$v)
                              },
                              expression: "form.road1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: {
                            prop: "subDistrict1",
                            label: "Sub-District",
                          },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.subDistrict1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "subDistrict1", $$v)
                              },
                              expression: "form.subDistrict1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "district1", label: "District" },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.district1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "district1", $$v)
                              },
                              expression: "form.district1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "province1", label: "Province" },
                        },
                        [
                          _c("el-input", {
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.province1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "province1", $$v)
                              },
                              expression: "form.province1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "zipCode1", label: "Zip Code" },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "5" },
                            on: { input: _vm.isNumber },
                            model: {
                              value: _vm.form.zipCode1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "zipCode1", $$v)
                              },
                              expression: "form.zipCode1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "contactNo1", label: "Contact No." },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "11" },
                            on: { input: _vm.isContactNumber },
                            model: {
                              value: _vm.form.contactNo1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contactNo1", $$v)
                              },
                              expression: "form.contactNo1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "email1", label: "Email" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.email1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "email1", $$v)
                              },
                              expression: "form.email1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSubline,
                      expression: "!isSubline",
                    },
                  ],
                  staticStyle: { "margin-top": "18px" },
                  attrs: { "body-style": "padding:0px;" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix header",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_vm._v("\n          Billing Address\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block text" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              staticStyle: { "max-width": "550px" },
                              model: {
                                value: _vm.addressOptions,
                                callback: function ($$v) {
                                  _vm.addressOptions = $$v
                                },
                                expression: "addressOptions",
                              },
                            },
                            [
                              _c("el-checkbox", {
                                attrs: {
                                  label: "Same as Contact Address",
                                  checked: _vm.isCopyAddress,
                                },
                                on: { change: _vm.checkAddress },
                              }),
                              _vm._v(" "),
                              _c("el-checkbox", {
                                attrs: {
                                  label: "Printed Bill",
                                  checked: _vm.isPrinted,
                                },
                                on: { change: _vm.checkPrintedBill },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: {
                            prop: "addressLineNo2",
                            label: "Present Address No.",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.blurAddressLineNo },
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.addressLineNo2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "addressLineNo2", $$v)
                              },
                              expression: "form.addressLineNo2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: {
                            prop: "village2",
                            label: "Village/Building",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.blurVillage },
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.village2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "village2", $$v)
                              },
                              expression: "form.village2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "moo2", label: "Moo" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.blurMoo },
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.moo2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "moo2", $$v)
                              },
                              expression: "form.moo2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "soi2", label: "Soi" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.blurSoi },
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.soi2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "soi2", $$v)
                              },
                              expression: "form.soi2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "road2", label: "Road" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.blurRoad },
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.road2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "road2", $$v)
                              },
                              expression: "form.road2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: {
                            prop: "subDistrict2",
                            label: "Sub-District",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.blurSubDistrict },
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.subDistrict2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "subDistrict2", $$v)
                              },
                              expression: "form.subDistrict2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "district2", label: "District" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.blurDistrict },
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.district2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "district2", $$v)
                              },
                              expression: "form.district2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "province2", label: "Province" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.blurProvince },
                            on: { input: _vm.loadSameAddress },
                            model: {
                              value: _vm.form.province2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "province2", $$v)
                              },
                              expression: "form.province2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "address-form-width",
                          staticStyle: { "max-width": "500px" },
                          attrs: { prop: "zipCode2", label: "Zip Code" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "5",
                              disabled: _vm.blurZipCode,
                            },
                            on: { input: _vm.isNumber },
                            model: {
                              value: _vm.form.zipCode2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "zipCode2", $$v)
                              },
                              expression: "form.zipCode2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSubline,
                      expression: "!isSubline",
                    },
                  ],
                  staticStyle: { "margin-top": "18px" },
                  attrs: { "body-style": "padding:0px;" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix header",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_vm._v("\n          Agent Details\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block text" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { float: "left", "margin-top": "18px" },
                          attrs: { prop: "agent", label: "Agent" },
                        },
                        [
                          _c("el-autocomplete", {
                            ref: "autocomplete",
                            staticStyle: { width: "320px" },
                            attrs: {
                              "fetch-suggestions": _vm.querySearchAsync,
                              placeholder: "Please search and select",
                              "trigger-on-focus": false,
                            },
                            on: { select: _vm.handleSelect },
                            model: {
                              value: _vm.form.agent,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "agent", $$v)
                              },
                              expression: "form.agent",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "clearAgentButton",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.clearSelect()
                            },
                          },
                        },
                        [_vm._v("Clear\n          ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                { staticStyle: { "margin-top": "18px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.next },
                    },
                    [_vm._v("Next")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "sub_form",
          attrs: {
            model: _vm.sub_form,
            rules: _vm.sub_rules,
            "label-width": "180px",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchSubline && _vm.isSubline,
                  expression: "searchSubline && isSubline",
                },
              ],
            },
            [
              _c(
                "el-card",
                {
                  staticStyle: { "margin-top": "18px" },
                  attrs: { "body-style": "padding:0px;" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix header",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_vm._v("\n          Customer Information\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block text" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            "max-width": "450px",
                            "padding-top": "18px",
                          },
                          attrs: { prop: "accountID", label: "Account ID" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.sub_form.accountID,
                              callback: function ($$v) {
                                _vm.$set(_vm.sub_form, "accountID", $$v)
                              },
                              expression: "sub_form.accountID",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "max-width": "450px" },
                          attrs: { prop: "name", label: "Name" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.sub_form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.sub_form, "name", $$v)
                              },
                              expression: "sub_form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "max-width": "550px" },
                          attrs: {
                            prop: "identificationType",
                            label: "Identification Type",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.idTypesLoading,
                                  expression: "idTypesLoading",
                                },
                              ],
                              ref: "select",
                              staticStyle: { width: "350px" },
                              attrs: {
                                placeholder:
                                  "Please select one of the identification type",
                                disabled: "",
                              },
                              model: {
                                value: _vm.sub_form.identificationType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.sub_form,
                                    "identificationType",
                                    $$v
                                  )
                                },
                                expression: "sub_form.identificationType",
                              },
                            },
                            _vm._l(_vm.idTypes, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "max-width": "450px" },
                          attrs: { prop: "idNo", label: "Identification No." },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.sub_form.idNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.sub_form, "idNo", $$v)
                              },
                              expression: "sub_form.idNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                {
                  staticStyle: { "margin-top": "18px" },
                  attrs: { "body-style": "padding:0px;" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix header",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_vm._v("\n          Agent Details\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block text" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { float: "left", "margin-top": "18px" },
                          attrs: { prop: "agent", label: "Agent" },
                        },
                        [
                          _c("el-autocomplete", {
                            ref: "autocomplete",
                            staticStyle: { width: "320px" },
                            attrs: {
                              "fetch-suggestions": _vm.querySearchAsync,
                              placeholder: "Please search and select",
                              "trigger-on-focus": false,
                            },
                            on: { select: _vm.handleSubSelect },
                            model: {
                              value: _vm.sub_form.agent,
                              callback: function ($$v) {
                                _vm.$set(_vm.sub_form, "agent", $$v)
                              },
                              expression: "sub_form.agent",
                            },
                          }),
                          _vm._v(" "),
                          _vm.displaySubAgentAlert
                            ? _c("p", { staticClass: "invalidAgentMessage" }, [
                                _vm._v(
                                  "Please select agent before proceed to next step"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "clearAgentButton",
                          attrs: { type: "primary" },
                          on: { click: _vm.clearSelect },
                        },
                        [_vm._v("Clear\n          ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-card",
                { staticStyle: { "margin-top": "18px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.sub_next },
                    },
                    [_vm._v("Next")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
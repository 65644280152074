"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

var _default = {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    count: {
      type: String,
      default: ''
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
        icon = _context$props.icon,
        title = _context$props.title,
        count = _context$props.count;
    var vnodes = [];
    var alertBadge = '';
    var alertCount = '';

    if (count !== '') {
      var getNum = parseInt(count);

      if (getNum === 0) {
        alertBadge = '';
        alertCount = '';
      } else {
        alertBadge = getNum.toString();
        alertCount = '(' + getNum.toString() + ')';
      }
    }

    if (icon) {
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
      vnodes.push(h("el-badge", {
        "attrs": {
          "value": alertBadge
        },
        "class": 'count-badge'
      }));
    }

    if (title) {
      vnodes.push(h("span", {
        "slot": 'title'
      }, [title, " ", h("span", {
        "class": 'count-no-collapse',
        "style": 'color: #f4516c;'
      }, [alertCount])]));
    }

    return vnodes;
  }
};
exports.default = _default;
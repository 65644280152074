var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true },
        },
      ],
      staticClass: "createPost-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "editTncForm",
          attrs: {
            model: _vm.editTncForm,
            "label-width": "150px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "createPost-main-container" },
            [
              _c("alert-list", {
                attrs: { "alert-message": _vm.alertMessage },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "750px" },
                  attrs: { label: "Status:" },
                },
                [
                  _vm.editTncForm.active === true
                    ? _c(
                        "el-col",
                        { attrs: { span: 2.5 } },
                        [
                          _vm._v("\n           "),
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: _vm.getActiveTagType(
                                  _vm.editTncForm.active
                                ),
                              },
                            },
                            [_vm._v("Active")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editTncForm.active === false
                    ? _c(
                        "el-col",
                        { attrs: { span: 2.5 } },
                        [
                          _vm._v("\n           "),
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: _vm.getActiveTagType(
                                  _vm.editTncForm.active
                                ),
                              },
                            },
                            [_vm._v("Not Active")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editTncForm.active === true
                    ? _c(
                        "el-col",
                        { attrs: { span: 2.5 } },
                        [
                          _vm._v("\n           "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.deactivateButton()
                                },
                              },
                            },
                            [_vm._v("Deactivate")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editTncForm.active === false
                    ? _c(
                        "el-col",
                        { attrs: { span: 2.5 } },
                        [
                          _vm._v("\n           "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.activateButton()
                                },
                              },
                            },
                            [_vm._v("Activate")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "version", label: "Version:" },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.editTncForm.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.editTncForm, "version", $$v)
                      },
                      expression: "editTncForm.version",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "startDate", label: "Start Date:" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Pick a Start Date",
                      format: "yyyy/MM/dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickStartDate,
                    },
                    model: {
                      value: _vm.editTncForm.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.editTncForm, "startDate", $$v)
                      },
                      expression: "editTncForm.startDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "max-width": "550px" },
                  attrs: { prop: "endDate", label: "End Date:" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Pick a End Date",
                      format: "yyyy/MM/dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickEndDate,
                    },
                    model: {
                      value: _vm.editTncForm.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.editTncForm, "endDate", $$v)
                      },
                      expression: "editTncForm.endDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitEdit()
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }